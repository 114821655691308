/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vkplay-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 36 36',
    data: '<path pid="0" d="M18.756 0C26.892 0 30.96 0 33.48 2.52 36 5.04 36 9.108 36 17.244v1.512c0 8.136 0 12.204-2.52 14.724C30.96 36 26.892 36 18.756 36h-1.512C9.108 36 5.04 36 2.52 33.48 0 30.96 0 26.892 0 18.756v-1.512C0 9.108 0 5.04 2.52 2.52 5.04 0 9.108 0 17.244 0h1.512z" _fill="#07F"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M35.895 10.152C36 12.098 36 14.427 36 17.244v1.512c0 2.166 0 4.044-.048 5.681H.048C0 22.8 0 20.922 0 18.756v-1.512c0-2.817 0-5.146.105-7.092h35.79zM.048 24.44v.015c4.712 2.3 11.04 3.702 17.942 3.702 6.908 0 13.249-1.408 17.962-3.712v-.005H.048z" _fill="#0009B4"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M25.037 16.58c-.891 0-1.613.739-1.613 1.65 0 .91.722 1.649 1.613 1.649s1.614-.739 1.614-1.65c0-.91-.723-1.649-1.614-1.649zm-5.192 1.65c0-2.932 2.324-5.308 5.192-5.308 2.868 0 5.192 2.376 5.192 5.308 0 2.93-2.324 5.307-5.192 5.307-2.868 0-5.192-2.376-5.192-5.307zM8.633 18.4L5.94 21.092l2.548 2.549 2.694-2.694 2.694 2.694 2.549-2.548-2.695-2.695 2.695-2.694-2.549-2.549-2.694 2.695-2.694-2.694-2.549 2.549 2.694 2.693z" _fill="#fff"/>'
  }
})
