import { Ref, ref } from 'vue'

import { FreemiumPosition, IFreemiumGoal, IFreemiumGoalCard, IFreemiumSetup } from 'lib/types/streamer-freemium/freemium-setup'
import { IFreemiumWidget } from 'lib/types/streamer-freemium/freemium-widget'

import { Freemium } from '.'

export class Goal {
  freemium: Freemium
  data: Ref<IFreemiumGoal|null> = ref(null)
  progress: Ref<IFreemiumWidget['goalProgress']|null> = ref(null)
  position: Ref<FreemiumPosition> = ref(FreemiumPosition.RIGHT_TOP_CORNER)
  currentCard: Ref<IFreemiumGoalCard> = ref('progress')
  private cardIntervalId: NodeJS.Timer | null = null
  private readonly CARD_CHANGE_INTERVAL = 1000 * 15

  constructor (freemium: Freemium) {
    this.freemium = freemium
  }

  setup (data: IFreemiumSetup['goal']) {
    if (!data) return

    this.data.value = data
    this.position.value = data.position
  }

  updateProgress (progress: IFreemiumWidget['goalProgress']) {
    this.progress.value = (this.data.value?.value || 0) + progress
  }

  loopCards () {
    if (this.cardIntervalId) {
      clearInterval(this.cardIntervalId as NodeJS.Timeout)
      this.currentCard.value = 'progress'
    }

    const isNotPreview = !this.freemium.preview.isActive.value
    const INTERVAL = isNotPreview ? this.CARD_CHANGE_INTERVAL : this.freemium.preview.PREVIEW_DURATION / 3

    this.cardIntervalId = setInterval(() => {
      let nextCard: IFreemiumGoalCard = 'progress'
      const supporters = this.freemium.supporters.top.value
      const hasNoSupporters = !supporters.topToday.length && !supporters.top.length

      switch (this.currentCard.value) {
        case 'progress':
          if (hasNoSupporters && isNotPreview) {
            nextCard = 'qr'
            break
          }
          if (!supporters.topToday.length) {
            nextCard = 'topSupportersAll'
            break
          }
          nextCard = 'topSupportersDay'
          break

        case 'topSupportersDay':
          if (!supporters.top.length) {
            nextCard = 'qr'
            break
          }
          nextCard = 'topSupportersAll'
          break

        case 'topSupportersAll':
          nextCard = 'qr'
          break

        case 'qr':
          nextCard = 'progress'
          break
      }

      this.currentCard.value = nextCard
    }, INTERVAL)
  }
}
