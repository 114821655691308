import { PartnerAdSetPayType } from 'lib/types/partner-ad-set'
import { IPrerollAdSet, IPrerollAdSetModel } from 'lib/types/partner-ad-set/preroll'
import { IPrerollAdSetPayloadTo } from 'lib/types/payloads/partner-ad-set/preroll'

export const prerollAdSetToModelAdapter = (payload: IPrerollAdSet): IPrerollAdSetModel => {
  return {
    title: payload.title,
    title_alternative: payload.title_alternative,
    description: payload.description,
    platform: payload.platform,
    start: payload.start,
    end: payload.end,
    payType: PartnerAdSetPayType.IMPRESSIONS,
    bidCap: payload.bid_cap,
    bidCpa: payload.bid_cpa,
    format: payload.format.id,
    impressions: payload.impressions,
    budget: payload.budget,
    excludeStreamers: payload.targeting.exclude_streamers,
    streamers: payload.targeting.streamers,
    languages: payload.targeting.broadcaster_languages,
    excludeLanguages: payload.targeting.exclude_languages,
    countries: payload.targeting.countries,
    excludeCountries: payload.targeting.exclude_countries,
    tags: payload.targeting.tags,
    excludeTags: payload.targeting.exclude_tags,
    productUrl: payload.product_url,
    videoDescriptionText: payload.video_description_text,
    unit: payload.attachments?.unit || null,
    enabled: payload.visible,
    targetCtr: payload.target_ctr,
    targetCpa: payload.target_cpa,
    pixelClicks: payload.pixel_clicks.map((item, index) => {
      if (typeof item === 'string') {
        return {
          id: index,
          value: item,
        }
      }
      return item
    }),
    pixelClicksScripts: payload.pixel_clicks_scripts,
    legalCompliance: {
      erid: {
        media: payload.legal_compliance?.erid.media || null,
        text: payload.legal_compliance?.erid.text || null,
      },
      marker: {
        media: payload.legal_compliance?.marker.media || null,
        text: payload.legal_compliance?.marker.text || null,
      },
    },
  }
}

export const prerollAdSetModelToPayloadAdapter = (model: IPrerollAdSetModel): IPrerollAdSetPayloadTo => {
  const result: IPrerollAdSetPayloadTo = {
    title: model.title,
    description: model.description,
    format: model.format,
    platform: model.platform,
    start: model.start,
    end: model.end,
    payable_type: model.payType,
    bid_cap: model.bidCap,
    bid_cpa: model.bidCpa,
    impressions: model.impressions,
    budget: model.budget,
    product_url: model.productUrl,
    video_description_text: model.videoDescriptionText,
    exclude_streamers: model.excludeStreamers,
    streamers: model.streamers.map(streamer => streamer.id),
    exclude_countries: model.excludeCountries,
    countries: model.countries,
    exclude_languages: model.excludeLanguages,
    broadcaster_languages: model.languages,
    exclude_tags: model.excludeTags,
    tags: model.tags,
    visible: model.enabled,
    target_ctr: model.targetCtr,
    target_cpa: model.targetCpa,
    pixel_clicks: model.pixelClicks.map(item => item.value),
    pixel_clicks_scripts: model.pixelClicksScripts,
  }

  // @ts-expect-error: this works
  const unitKey = model?.unit?.key
  if (unitKey) {
    result.unit = unitKey
  }
  return result
}
