import { Widget } from 'Widget/class/Widget'

import { ScreenshotApi } from 'lib/api/screenshot-instance'
import { Logger } from 'lib/helpers/handleLog'
import { ICreative, IDemoCreative, IPromoCreative } from 'lib/types/creative'
import { ErrorMessage } from 'lib/types/error-message'
import { Platform } from 'lib/types/platform'

import { SCREENSHOT_DELAY, SCREENSHOT_TIMEOUT } from '../constants/delays'
import { wait } from '../utils/wait'

export class Screenshot {
  widget: Widget
  enabled = false
  private timer: NodeJS.Timeout | null = null

  constructor (widget: Widget) {
    this.widget = widget
  }

  enable () {
    if (this.enabled) return

    this.enabled = true

    this.timer = setTimeout(() => {
      this.enabled = false
    }, SCREENSHOT_TIMEOUT)
  }

  disable () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.enabled = false
  }

  async makeScreenshot (
    creative: ICreative | IDemoCreative | IPromoCreative,
    attempt = 1,
  ) {
    console.log('enabled', this.enabled)
    console.log('creative.adSet?.make_screenshots', creative.adSet?.make_screenshots)
    if (!this.enabled) {
      if (!creative.adSet?.make_screenshots) {
        return
      }
    }

    if (this.widget.pusher.subscribersCount.value > 1) return

    if (!creative || !this.widget.data) return

    await wait(SCREENSHOT_DELAY)

    const data = this.getScreenshotData(creative)

    if (!data) return

    try {
      await ScreenshotApi.post(
        this.widget.data.value.platform,
        JSON.stringify(data),
        { showMessage: false },
      )
      Logger.debug(`Make screenshot for: ${creative.slug}`)
    }
    catch (err) {
      if (attempt > 0) {
        setTimeout(() => {
          this.makeScreenshot(creative, attempt - 1)
        }, 45 * 1000)
      }
      else {
        Logger.critical(ErrorMessage.WIDGET_MAKE_SCREENSHOT, false, err)
      }
    }
  }

  private getScreenshotData (creative: ICreative | IDemoCreative | IPromoCreative) {
    const widgetData = this.widget.data.value

    if (widgetData.platform === Platform.YOUTUBE) {
      return {
        slug: this.widget.streamInfo?.id,
        ad: creative.slug,
        impression: creative.impressionSlug,
      }
    }

    switch (widgetData.platform) {
      case Platform.TWITCH:
      case Platform.TROVO:
      case Platform.VK_PLAY:
        return {
          nickname: widgetData[widgetData.platform]?.nickname,
          ad: creative.slug,
          impression: creative.impressionSlug,
        }
      default:
        return null
    }
  }
}
