import { Ref, ref } from 'vue'

import { useRootLocaleObj } from 'lib/helpers/useLocale'
import { FreemiumMessagesFrequency } from 'lib/types/streamer-freemium/freemium-alerts'
import { IFreemiumChat, IFreemiumSetup } from 'lib/types/streamer-freemium/freemium-setup'

import { Chatbot } from '../Chatbot'

import { Freemium } from '.'

export class Chat {
  private freemium: Freemium
  private chatbot: Chatbot;
  data: Ref<IFreemiumChat|null> = ref(null)
  frequency: Ref<FreemiumMessagesFrequency> = ref(FreemiumMessagesFrequency.DISABLED)
  private messagesIntervalId: NodeJS.Timer | null = null
  private freemiumMessages = useRootLocaleObj('views.widget.freemium.chatbotMsgs') as unknown as string[]
  private lastFreemiumMessages: string[] = []

  constructor (freemium: Freemium) {
    this.freemium = freemium
    this.chatbot = new Chatbot(this.freemium.widget)
  }

  setup (data: IFreemiumSetup) {
    this.data.value = data.chat
    this.frequency.value = data.chat_message_frequency
  }

  init () {
    const chatIsNotActive = !this.data.value?.enabled || this.frequency.value === FreemiumMessagesFrequency.DISABLED

    if (!this.freemium.goal || chatIsNotActive) {
      return
    }

    if (this.messagesIntervalId) {
      clearInterval(this.messagesIntervalId as NodeJS.Timeout)
    }

    this.messagesIntervalId = setInterval(() => {
      if (window.obsstudio) {
        window.obsstudio.getStatus((status) => {
          if (!status.streaming) {
            return
          }
          this.chatbot.sendFreemiumMessage(this.getMessage())
        })
      }
    }, 1000 * 60 * this.frequency.value)
  }

  private getMessage () {
    const streamer = this.freemium.widget.data.value.streamer

    const streamerLocale = streamer?.locale === 'en' ? '' : streamer?.locale + '/'
    const link = `https://uplify.link/${streamerLocale}${streamer?.user_id}`
    const randomgIndex = Math.floor(Math.random() * this.freemiumMessages.length)
    const getRandom = () => this.freemiumMessages[randomgIndex].replace('{link}', link)

    if (this.lastFreemiumMessages.length >= 3) {
      this.lastFreemiumMessages = []
    }

    let message = getRandom()
    do {
      message = getRandom()
    } while (this.lastFreemiumMessages.includes(message))

    this.lastFreemiumMessages.push(message)
    return message
  }
}
