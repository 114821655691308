import { uniqueId } from 'lodash'

import { ICreative, IDemoCreative, IPromoCreative, IStreamInfo } from 'lib/types/creative'
import { ICreativePayload, IDemoCreativePayload, IPromoCreativePayload, IRealDemoCreativePayload } from 'lib/types/payloads/creative'

export function payloadToCreativeAdapter (dto: ICreativePayload, stream: IStreamInfo): ICreative {
  return {
    id: dto.id,
    uuid: uniqueId('uiid_'),
    slug: dto.slug,
    impressionSlug: dto.impression_slug || null,
    viewersCount: dto.viewers_count,
    qr: {
      code: dto.qr_code,
      link: dto.qr_link,
    },
    companion: {
      cta: dto.companion_cta,
      heading: dto.companion_heading,
      text: dto.companion_text,
    },
    pixels: {
      clicks: dto.pixel_clicks,
      impressions: dto.pixel_impressions,
    },
    adSet: dto.ad_set,
    attachments: dto.attachments,
    stream,
  }
}

export function payloadDemoToCreativeAdapter (dto: IDemoCreativePayload | IRealDemoCreativePayload | IPromoCreativePayload): IDemoCreative | IPromoCreative {
  const format = 'format_code' in dto.ad_set ? dto.ad_set.format_code : dto.ad_set.format
  const campaignType = 'campaign' in dto ? dto.campaign.type_code : dto.ad_set.campaign.type

  return {
    id: parseInt(uniqueId()),
    uuid: uniqueId('uiid_'),
    slug: 'Demo',
    viewersCount: 0,
    impressionSlug: null,
    qr: {
      code: dto.qr_code,
      link: dto.qr_link,
    },
    companion: {
      cta: ('companion_cta' in dto) ? dto.companion_cta : '',
      heading: ('companion_heading' in dto) ? dto.companion_heading : '',
      text: ('companion_text' in dto) ? dto.companion_text : '',
    },
    pixels: {
      clicks: [],
      impressions: [],
      inspections: [],
    },
    adSet: {
      format,
      campaign: {
        type: campaignType,
      },
      advertiser: {
        legal_name: dto.ad_set?.advertiser?.legal_name,
        tin: dto.ad_set?.advertiser?.tin,
      },
      make_screenshots: dto.ad_set.make_screenshots,
    },
    attachments: dto.attachments,
  }
}
