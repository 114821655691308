import { FreemiumPosition, IFreemiumPoll, IFreemiumSetup } from 'lib/types/streamer-freemium/freemium-setup'
import { IFreemiumWidget } from 'lib/types/streamer-freemium/freemium-widget'
import { Ref, ref } from 'vue'

import { Freemium } from '.'

export class Poll {
  freemium: Freemium
  data: Ref<IFreemiumPoll|null> = ref(null)
  results: Ref<IFreemiumWidget['pollResults']|null> = ref(null)
  position: Ref<FreemiumPosition> = ref(FreemiumPosition.RIGHT_BOTTOM_CORNER)
  private cardIntervalId: NodeJS.Timer | null = null

  constructor (freemium: Freemium) {
    this.freemium = freemium
  }

  setup (data: IFreemiumSetup['poll']) {
    if (!data) return

    this.data.value = data
    this.position.value = data.position
  }

  updateResults (results: IFreemiumWidget['pollResults']) {
    this.results.value = results
  }

  initTimer () {
    if (!this.data) return

    if (this.cardIntervalId) {
      clearInterval(this.cardIntervalId as NodeJS.Timeout)
    }

    if (this.data.value?.end_condition !== 'duration') {
      return
    }

    if (!this.freemium.preview.isActive.value && !this.data.value?.remainder_duration) {
      return
    }

    this.cardIntervalId = setInterval(() => {
      if (!this.data.value?.remainder_duration || this.data.value.remainder_duration <= 0) {
        clearInterval(this.cardIntervalId as NodeJS.Timeout)
        return
      }

      this.data.value.remainder_duration--
    }, 1000)
  }
}
