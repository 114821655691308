import { LocaleDictionary } from 'locales/locale-dictionary'

const dictionary: LocaleDictionary = {
  placeholder: {
    email: 'Email',
    password: 'Senha',
    currentPassword: 'Senha atual',
    newPassword: 'Nova senha',
    repeatPassword: 'Confirme a senha',
    phoneNumber: 'Telefone',
    translationLanguage: 'Idioma da transmissão',
    country: 'País',
    city: 'Cidade',
    enterCity: 'Introduza o nome da cidade',
    state: 'Estado',
    enterState: 'Introduza o nome do estado',
    zip: 'Publicar сode',
    address: 'Endereço',
    enterAddress: 'Introduzir morada',
    gender: 'Género',
    birthYear: 'Ano de nascimento',
    birthday: 'Data de nascimento',
    fullname: 'Full name', // !
    name: 'Primeiro nome',
    enterName: 'Introduza o primeiro nome',
    lastname: 'Apelido',
    enterLastname: 'Introduza o apelido',
    middlename: 'Nome do meio',
    enterMiddlename: 'Introduza o nome do meio',
    companyName: 'Nome da empresa',
    contactPerson: 'Nome completo',
    commonCurrency: 'Comum сurrency',
    campaign: {
      type: 'Tipo de campanha',
      name: 'Nome da campanha',
    },
    inn: 'Número de imposto',
    iban: 'IBAN',
    bic: 'Código bancário BIC/SWIFT',
    paypalEmail: 'Introduza o e-mail ligado ao PayPal',
    selectDate: 'Seleccione a data',
    chooseCategories: 'Escolher categorias',
    optional: 'opcional',
    passportNumber: 'Passport Number', // !
    panCard: 'PAN card', //!
    panCardIfUpload: 'If you upload PAN card we deduct 10% TDS otherwise 20% TDS', //!
    panCardNumber: 'PAN card Number', // !
    enterPassportNumber: 'Enter PN', // !
    enterPanCardNumber: 'Enter PAN', // !
    payoutMode: 'Payout Mode', // !
    choosePayoutMode: 'Choose Payout Mode', // !
    accountVpa: 'Account VPA', // !
    bankIFSC: 'Bank IFSC', // !
    beneficiaryAccountNumber: 'Beneficiary account number', // !
    partnerSearch: 'Pesquisar por ID ou nome',
    choose: 'Selecione',
    excludeSelected: 'Excluir selecionado',
    dragAFile: 'Arraste o arquivo',
    dragImageHint: 'JPG ou PNG, tamanho {size} MB ou menos',
  },
  partnerSearchFilter: {
    add: 'Filtr',
    status: 'Estado',
    platform: 'Platform',
    advertiser: 'Anunciante',
    allAdvertisers: 'Todos os Anunciantes',
  },
  button: {
    signIn: 'Iniciar Sessão',
    save: 'Salvar',
    saving: 'Poupança ..',
    saved: 'Guardado',
    send: {
      static: 'Enviar',
      pending: 'Envio para ..',
      success: 'Enviado',
    },
    requestHasBeenSent: 'O pedido foi enviado',
    recoverySend: 'Enviar link de recuperação',
    recoverySent: 'Ligação enviada',
    passwordChanged: 'Senha alterada',
    runAd: 'Publicar anúncio',
    toLink: 'Ligação',
    remove: 'Eliminar',
    delete: {
      static: 'Eliminar',
      process: 'Eliminação',
      success: 'Eliminado',
      successFeminine: 'Eliminado',
      successMasculine: 'Eliminado',
      successPlural: 'Eliminado',
    },
    create: {
      campaigns: 'Criar Campanha',
      groups: 'Criar Grupo',
      creatives: 'Criar Criativo',
    },
    enable: 'Habilitar',
    disable: 'Desativar',
    sendPreview: {
      static: 'Enviar Pré-visualização',
      success: 'Pré-visualização Enviada',
    },
    chooseFile: {
      static: 'Escolha um ficheiro',
    },
    back: {
      static: 'Voltar',
    },
    attach: {
      static: 'Anexar',
    },
    join: {
      static: 'Entrar no',
    },
    cancel: {
      static: 'Cancelar',
    },
    next: {
      static: 'Próximo',
    },
    resetLink: 'Link de reinicialização',
    resetQR: 'Repor o código QR',
    showQR: 'Mostrar código QR',
    hideQR: 'Ocultar código QR',
    showManager: 'Open Mobile Manager',
    copyLink: {
      static: 'Copiar Link',
      success: 'Link copiado',
    },
    copy: {
      static: 'Cópia de',
      success: 'Copiada',
    },
    saveChanges: {
      static: 'Salvar alterações',
      pending: 'Salvando ..',
      success: 'Alterações salvas',
    },
    getStarted: 'Get started',
    copyFromExisting: 'Copiar de existente',
    loadMore: 'Carregue mais',
  },
  validator: {
    required: 'Campo obrigatório',
    invalidEmail: 'Formato de e-mail incorrecto',
    invalidPhoneNumber: 'Formato incorrecto do número de telefone',
    birthYearLess: 'O ano deve ser inferior a',
    birthYearMore: 'O ano deve ser superior a',
    shortPassword: 'A palavra-passe deve ser superior a 8 caracteres',
    matchPassword: 'As palavras-passe não coincidem',
    positiveNum: 'Insira um número positivo',
    alphanumeric: 'Only letters and numbers are allowed',
    inn: 'Valor incorreto',
    bic: 'Valor incorreto',
    swiftAllowedCharacters: 'O código SWIFT pode conter apenas letras e números',
    swiftLengthDescription: 'O código SWIFT deve ser de 8 ou 11 caracteres',
    numbersAndSpaces: 'Formato incorreto',
    userInnNotFound: 'Autônomo com este TIN não encontrado',
    bankAccount: 'Digite 20 dígitos sem espaços',
    onlyCyrillic: 'Insira o valor em cirílico',
    urlIsInvalid: 'O URL é inválido',
    embed: 'Forneça um link de incorporação válido',
    youtubeUrl: 'Link inválido do YouTube',
    uniqueYoutubeUrl: 'Você já adicionou vídeo com este id',
  },
  helpers: {
    perHour: 'Uma vez 1 hora',
    perMin: 'Uma vez {minutes} min',
    seconds: '{n} segundos | {n} segundo | {n} segundos',
    streamers: '{n} criadores | {n} criadores | {n} criadores',
    inSeconds: 'em {n} segundos | em {n} segundo | em {n} segundos',
    someOfSome: 'de {0} de {1}',
    timeAgo: {
      mins: '{n} minutos atrás | {n} minuto atrás | {n} minutos atrás',
      minutes: '{n} minutos atrás | {n} minuto atrás | {n} minutos atrás',
      hours: '{n} horas atrás | {n} hora atrás | {n} horas atrás',
      days: '{n} dias atrás | {n} dia atrás | {n} dias atrás',
      months: '{n} meses atrás | {n} mês atrás | {n} meses atrás',
      years: '{n} anos atrás | {n} ano atrás | {n} anos atrás',
    },
    timeLeft: {
      mins: '{n} minutos restantes | {n} minuto restante | {n} minutos restantes',
      minutes: '{n} minutos restantes | {n} minuto restante | {n} minutos restantes',
      hours: '{n} horas restantes | {n} hora restante | {n} horas restantes',
      days: '{n} dias restantes | {n} dia restante | {n} dias restantes',
      months: '{n} meses restantes | {n} mês restante | {n} meses restantes',
      years: '{n} anos restantes | {n} ano restante | {n} anos restantes',
    },
  },
  pagination: {
    next: 'Próximo',
    prev: 'Voltar',
  },
  sidebar: {
    navigation: {
      streamer: {
        home: {
          title: 'Início',
        },
        dashboard: {
          title: 'Dashboard',
        },
        freemium: {
          title: 'Uplify Link',
        },
        campaigns: {
          title: 'Campanhas',
          subitems: {
            'campaigns-livestream': 'Livestream',
            'campaigns-preroll': 'Preroll',
          },
        },
        wallet: {
          title: 'Carteira',
        },
        widget: {
          title: 'Configurações',
        },
        referrals: {
          title: 'Referrals',
        },
        extension: {
          title: 'Extensões',
        },
        debug: {
          title: 'Debug',
        },
      },
      partner: {
        campaigns: {
          title: 'Campanhas',
          subitems: {
            'brand-awareness': 'Patrocínio',
            performance: 'Interativo',
            preroll: 'Preroll',
          },
        },
        advertisers: {
          title: 'Anunciantes',
        },
        holdings: {
          title: 'Holdings',
        },
        creators: {
          title: 'Agência',
        },
        debug: {
          title: 'Debug',
        },
      },
      profile: {
        settings: 'Configurações de conta',
        logout: 'Sair',
      },
    },
    links: {
      help: {
        label: 'Ajuda',
        url: 'https://fanatical-fly-511.notion.site/Uplify-Portuguese-64b3d5c4677f4eec922f48fae36d9079',
      },
      news: {
        label: 'O que há de novo',
        url: '',
      },
      feedback: {
        label: 'Feedback',
        url: 'https://discord.com/channels/465868803268542475/1118478966467080283',
      },
      discord: {
        label: 'Discord',
        url: 'https://discord.gg/uplify',
      },
    },
    requestDemo: 'Eu preciso de ajuda',
    socials: {
      title: 'Siga-nos',
    },
  },
  other: {
    adblock: {
      bold: 'O Adblock está a bloquear a funcionalidade da aplicação.',
      normal: 'Por favor adicione Uplify à lista branca.',
    },
    advice: {
      attention: 'Attention',
      hint: 'Dica',
    },
    uploader: {
      loading: 'Carregamento',
      processing: 'Processing',
      declined: 'Declined',
      fileUploadError: 'File upload error',
    },
    twitchAlert: 'The site is undergoing technical work. Registration and authorization via Twitch is not available until May 19. See <a href="https://discord.gg/uplify" target="_blank">Discord</a> for details.',
    noData: 'Sem dados',
    enabled: 'Enabled',
    disabled: 'Disabled',
    timeAgo: {
      timeAgoMins: '{n} minutes ago',
      timeAgoHours: '{n} hours ago',
      timeAgoDays: '{n} days ago',
      timeAgoMonths: '{n} months ago',
      timeAgoYears: '{n} year ago',
    },
  },
  phrases: {
    terms: 'Termos',
    privacy: 'Privacidade',
    help: 'Ajuda',
    loading: 'Carregamento',
    from: 'De',
    to: 'A',
  },
  closeDialog: {
    heading: 'Dados não salvos',
    text: 'Se você fechar a janela, os dados inseridos anteriormente serão excluídos.',
    btnSave: 'Continuar editando',
    btnContinue: 'Sair sem salvar',
  },
  links: {
    discord: 'https://discord.gg/uplify',
    terms: 'https://www.uplify.app/legal/terms',
    termsLink: 'https://www.uplify.app/legal/terms',
    privacy: 'https://www.uplify.app/legal/privacy-policy',
    howIsItCalculated: 'https://help.uplify.app/en/articles/5640884-how-much-can-i-earn',
    howToIncreaseCtr: 'https://help.uplify.app/en/articles/5640884-how-much-can-i-earn#h_62ec20388f',
    whenWillIReceiveMyPayouts: 'https://help.uplify.app/en/articles/5640885-when-will-i-receive-my-payouts',
    learnMoreAboutComissions: 'https://help.uplify.app/en/articles/5640886-what-payout-methods-are-supported#h_e873868c36',
    whyDoWeNeedThisData: 'https://help.uplify.app/en/articles/5640886-what-payout-methods-are-supported#h_6423c40103',
    widgetSetup: 'https://fanatical-fly-511.notion.site/Defini-o-de-Widget-84d39ddc83114013ad29145f346f5717',
    chatbotSetup: 'https://fanatical-fly-511.notion.site/Cria-o-de-um-chatbot-aa29e84735584066a1fa7884c16bc6e4',
    chatbotSurvey: 'https://forms.gle/2QxGPNUgEbFu9WdK7',
    inStreamAdsFormat: 'https://help.uplify.app/en/articles/5640856-in-stream-ads-format',
    whatIsTier: 'https://help.uplify.app/en/articles/5640884-how-much-can-i-earn#h_56cf3a9018',
    whatsNew: 'https://www.uplify.app/categorias-de-noticias/novidades',
    whyAreWeCollectingThisData: 'https://fanatical-fly-511.notion.site/Permiss-es-dos-aplicativos-9ce6021bc5b2419fa2c9b2ad01b1b4c9',
    twitchExtension: 'https://dashboard.twitch.tv/extensions/wc13k0wnf9sk4omgod6p1r3os9kulv',
    takeSurveyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSezBt2RxWgHwrEwWhcdZ_nWO7TU7TUTy8Pv4J9N4kaoNvqPZQ/viewform?usp=sf_link',
  },
  demoInfo: {
    title: 'Você está em uma conta de demonstração',
    text: 'Algumas das características não funcionam e requerem registro',
    twitchBtn: 'Cadastre-se com Twitch',
    youtubeBtn: 'Cadastre-se com YouTube',
    trovoBtn: 'Cadastre-se com Trovo',
  },
  progress: {
    of: 'de',
    forecast: 'Previsão',
  },
  errors: {
    unknown: 'Erro desconhecido',
    wrongExtension: 'Extensão de arquivo errada',
    fileSize: 'Arquivo <b>{name}</b> ({size}MB) deve ser menor que {maxSize}MB',
  },
  campaignRow: {
    of: 'de',
    dailyActions: 'Ações diário',
    dailyLimit: 'Visualizações restantes',
    dailyActionsLimit: 'Ações diárias restantes',
    dailyActionsLimitLabel: 'O número total de ações disponíveis para toda a comunidade é exibido',
    dailyImpressions: 'Vistas diário',
    potentialIncome: 'Receita Potencial',
    yourIncome: 'Os seus rendimentos',
    yourCTR: 'Seu CTR',
    averageCTR: 'CTR médio',
    dateStart: 'Data de início',
    dateEnd: 'Data final',
    enable: 'Habilitar',
    disable: 'Desativar',
    attended: 'Atendido',
    toggling: 'Pendente..',
    tags: {
      undefinedFormat: 'Formato desconhecido',
      dailyLimitDepleted: 'Limite de hoje esgotado',
      totalLimitDepleted: 'Limite de hoje esgotado',
      setupExtension: 'Extensão de instalação',
      checkExtension: 'Verifique o status da extensão',
      extension: 'Extensão',
      actionBonus: 'Bônus de Ação',
      dailyActionsLimitDepleted: 'Limite de ação atingido',
      performanceDailyDepleted: 'Limite de hoje esgotado',
    },
    disabledUntil: 'Desativado até: {date}',
    reasons: {
      lowCTR: '🔥 Motivo: CTR baixo',
      lowCTRLink: {
        text: 'Como melhorar meu CTR?',
        href: 'https://www.notion.so/CTR-todo-o-que-voc-precisa-saber-f59965b014134b39800d87b2e3d7216c',
      },
      moderator: 'Motivo: decisão do moderador',
    },
    format: {
      fullscreen: 'Overlay 50%',
      pip: 'Overlay 15%',
    },
    totalImpressions: 'Visualizações disponíveis',
  },
  campaignSidebar: {
    potentialRevenue: 'Receita Potencial',
    totalRevenue: 'Sua receita total',
    revenue: 'Receita',
    estimatedRevenue: 'Receita estimada',
    ctr: 'Seu CTR',
    target_ctr: 'Alvo ctr',
    clicks: 'Cliques',
    date: 'Data final',
    dateStart: 'Data de início',
    dateEnd: 'Data final',
    paymentType: 'Tipo de pagamento',
    pricePerViews: 'Preço por 1000 vistas',
    pricePerAction: 'Preço por 1 ação',
    pricePerClick: 'Preço por 1 clique',
    frequency: 'Frequência',
    adFormat: 'Formato do anúncio',
    advertiserCategory: 'Categoria do anunciante',
    description: 'Descrição da campanha',
    creativePreview: 'Pré-visualização criativa',
    messageInDescription: 'Mensagem na descrição',
    chatMessage: 'Mensagem em chatbot',
    campaignOn: 'Campanha em',
    campaignOff: 'Campanha de lançamento',
    productLink: 'Link de produto',
    copyProductLink: 'Copiar link de produto pessoal',
    deliveredAtions: 'Ações entregues',
    deliveredImpressions: 'Vistas entregues',
    deliveredDailyActions: 'Ações entregues',
    deliveredClicks: 'Cliques entregues',
    downloadCreative: 'Baixar criativo',
    copyDescription: 'Descrição da cópia',
    videos: 'Link de vídeo',
    addNewVideo: 'Adicione outro vídeo',
  },
  intercom: {
    freemium: 'Oi! Quero experimentar o Uplify Link',
  },
  views: {
    auth: {
      streamerSignin: {
        title: 'Olá! 👋',
        subtitle: 'Entre na sua conta utilizando uma das suas contas da plataforma de streaming..',
        startWithTwitch: 'Comece com Twitch',
        startWithYoutube: 'Comece pelo YouTube',
        startWithTrovo: 'Comece pelo Trovo',
        startWithVkplay: 'Comece pelo VK Play',
        partnerLogin: 'Parceiros iniciam sessão',
        termsAndPolicy: 'Ao fazer login, você aceita os <a href="@:links.terms" target="_blank">Termos</a> e <a href="@:links.privacy" target="_blank">Privacidade</a>.',
        accountBlocked: 'A conta está bloqueada. Se isto for um erro, por favor',
        twitchAuthError: 'Erro de autorização de contração. Tente novamente ou',
        youtubeAuthError: 'Erro de autorização do YouTube. Tente novamente ou',
        trovoAuthError: 'Erro de autorização do Trovo. Tente novamente ou',
        vkplayAuthError: 'Erro de autorização do VK Play. Tente novamente ou',
        discordAuthError: 'Erro de autorização do Discord. Tente novamente ou',
        userError: 'N&atilde;o foi poss&iacute;vel obter dados sobre o&nbsp;usu&aacute;rio. Se&nbsp;voc&ecirc; n&atilde;o possui um&nbsp;canal, cri&aacute; -los e&nbsp;repeti -los novamente.',
        accountAlreadyExists: 'Você está tentando conectar uma plataforma vinculada a um perfil diferente.',
        contactSupport: 'apoio ao contacto',
        info: {
          block1: {
            title: 'Uplify ajuda a&nbsp;rentabilizar o&nbsp;conte&uacute;do da&nbsp;transmiss&atilde;o ao&nbsp;vivo de&nbsp;uma forma moderna.',
            linkText: 'Saiba mais sobre nós',
            linkHref: 'https://www.uplify.app/pt/para-criadores',
            videoHref: 'https://youtu.be/JKWaUjlIML0?si=vVppKgzscrBYX-6S',
          },
          block2: {
            title: 'Temos a&nbsp;confian&ccedil;a de&nbsp;<strong>35&nbsp;000 criadores</strong> e&nbsp;j&aacute;&nbsp;pagamos mais de&nbsp;<strong>$3&nbsp;000&nbsp;000</strong>.',
            linkText: 'Junte-se à nossa comunidade Discord',
            linkHref: 'https://discord.gg/uplify',
          },
          block3: {
            title: '<strong>Formatos n&atilde;o disruptivos</strong> de&nbsp;marcas de&nbsp;renome mundial.',
            linkText: 'Confira nossos estudos de caso',
            linkHref: 'https://www.uplify.app/pt/casos',
          },
        },
      },
      streamerSettings: {
        hi: 'Hi',
        subtitle: 'Estamos quase lá. Entretanto, vamos conhecer-nos melhor uns aos outros. Isto é necessário para o trabalho correcto dos anúncios.',
      },
      partnerSignin: {
        title: 'Parceiros iniciam sessão',
        forgotPassword: 'Esqueceu-se da palavra-passe?',
        streamerLogin: 'Criador iniciar sessão',
        wannaLaunchAd: 'Quer lançar um anúncio?',
        contactUs: 'Contacte-nos para se inscrever',
      },
      partnerRequest: {
        partnerSignUp: 'Parceiros inscrevem-se',
        partnerSignIn: 'Parceiros iniciam sessão',
        byStart: 'Ao clicar em "Enviar", aceita o',
        terms: 'Termos',
        and: 'e',
        policy: 'Privacidade',
        successMsg: 'Enviamos instruções descrevendo outras ações para seu e-mail. Nossa equipe entrará em contato com você em breve.',
      },
      resetPassword: {
        title: 'Reinicialização de senha',
        description: 'Introduza o e-mail associado à sua conta e enviar-lhe-emos a ligação de redefinição da palavra-passe.',
        partnerSignIn: 'Parceiros iniciam sessão',
      },
      newPassword: {
        title: 'Criar uma nova palavra-passe',
        descriptionFirstRow: 'A palavra-passe não pode ser inferior a 8 caracteres.',
        descriptionSecondRow: 'Não utilizar palavras-passe de outros sites.',
        successText: 'Senha alterada',
        openNow: 'Abrir agora',
        pageOpenIn: 'A página de início de sessão será aberta',
      },
      scopeTwitch: {
        heading: 'Antes de começarmos',
        headline: 'Para que o&nbsp;servi&ccedil;o Uplify funcione corretamente, precisamos de&nbsp;sua permiss&atilde;o para acessar as&nbsp;estat&iacute;sticas de&nbsp;seu canal e&nbsp;enviar mensagens de&nbsp;chat.',
        info: 'Respeitamos sua privacidade e&nbsp;tratamos seus dados com a&nbsp;m&aacute;xima sensibilidade.',
        link: 'Por que estamos coletando esses dados?',
        desciptions: [
          {
            title: 'Enviar mensagens ao&nbsp;vivo no&nbsp;Stream Chat e&nbsp;Salas',
            text: 'Este nos permite enviar mensagens de&nbsp;chat com um&nbsp;link para o&nbsp;site do&nbsp;patrocinador.',
          },
          {
            title: 'Obter seu endere&ccedil;o de&nbsp;e-mail',
            text: 'Seu e-mail &eacute;&nbsp;solicitado por Uplify para confirmar seu registro e&nbsp;enviar notifica&ccedil;&otilde;es importantes.',
          },
          {
            title: 'Visualize os&nbsp;dados de&nbsp;modera&ccedil;&atilde;o de&nbsp;seu canal incluindo Moderadores, Proibi&ccedil;&otilde;es, Timeouts e&nbsp;configura&ccedil;&otilde;es de&nbsp;Automod',
            text: 'Necess&aacute;rio para que o&nbsp;chatbot funcione corretamente e&nbsp;evite conflitos com outros chatbots em&nbsp;seu canal.',
          },
          {
            title: 'Veja a&nbsp;configura&ccedil;&atilde;o de&nbsp;transmiss&atilde;o de&nbsp;seu canal',
            text: 'Acesso somente leitura. Permite Uplify para otimizar a&nbsp;entrega de&nbsp;campanhas patrocinadas a&nbsp;voc&ecirc;.',
          },
          {
            title: 'Ver mensagens ao&nbsp;vivo no&nbsp;Stream Chat e&nbsp;Salas',
            text: 'Permite-nos ler mensagens de&nbsp;bate-papo de&nbsp;canal e&nbsp;conduzir pesquisas interativas.',
          },
        ],
        redirectBtn: 'Ir para o Twitch',
      },
      scopeYoutube: {
        heading: 'Antes de começarmos',
        headline: 'Para que o&nbsp;servi&ccedil;o Uplify funcione corretamente, precisamos de&nbsp;sua permiss&atilde;o para acessar as&nbsp;estat&iacute;sticas de&nbsp;seu canal e&nbsp;enviar mensagens de&nbsp;chat.',
        info: 'Respeitamos sua privacidade e&nbsp;tratamos seus dados com a&nbsp;m&aacute;xima sensibilidade.',
        link: 'Por que estamos coletando esses dados?',
        desciptions: [
          {
            title: 'Gerencie sua conta no&nbsp;YouTube',
            text: 'Permite-nos obter o&nbsp;n&uacute;mero de&nbsp;espectadores ativos na&nbsp;transmiss&atilde;o ao&nbsp;vivo, ler e&nbsp;enviar mensagens de&nbsp;chat com um&nbsp;link para o&nbsp;site do&nbsp;patrocinador.',
          },
        ],
        redirectBtn: 'Conecte o YouTube',
      },
      scopeTrovo: {
        heading: 'Antes de começarmos',
        headline: 'Para que o&nbsp;servi&ccedil;o Uplify funcione corretamente, precisamos de&nbsp;sua permiss&atilde;o para acessar as&nbsp;estat&iacute;sticas de&nbsp;seu canal e&nbsp;enviar mensagens de&nbsp;chat.',
        info: 'Respeitamos sua privacidade e&nbsp;tratamos seus dados com a&nbsp;m&aacute;xima sensibilidade.',
        link: 'Por que estamos coletando esses dados?',
        desciptions: [
          {
            title: 'Veja seu endere&ccedil;o de&nbsp;e-mail e&nbsp;outros perfis de&nbsp;usu&aacute;rio',
            text: 'Seu e-mail &eacute;&nbsp;solicitado por Uplify para confirmar seu registro e&nbsp;enviar notifica&ccedil;&otilde;es importantes.',
          },
          {
            title: 'Veja os&nbsp;detalhes de&nbsp;seu canal',
            text: 'Acesso somente leitura. Permite Uplify para otimizar a&nbsp;entrega de&nbsp;campanhas patrocinadas a&nbsp;voc&ecirc;.',
          },
          {
            title: 'Enviar bate-papos para meu canal',
            text: 'Este nos permite enviar mensagens de&nbsp;chat com um&nbsp;link para o&nbsp;site do&nbsp;patrocinador.',
          },
        ],
        redirectBtn: 'Ir para Trovo',
      },
      scopeVkplay: {
        heading: 'Antes de começarmos',
        headline: 'Para que o&nbsp;servi&ccedil;o Uplify funcione corretamente, precisamos de&nbsp;sua permiss&atilde;o para acessar as&nbsp;estat&iacute;sticas de&nbsp;seu canal e&nbsp;enviar mensagens de&nbsp;chat.',
        info: 'Respeitamos sua privacidade e&nbsp;tratamos seus dados com a&nbsp;m&aacute;xima sensibilidade.',
        link: 'Por que estamos coletando esses dados?',
        desciptions: [
          {
            title: 'Veja seu endere&ccedil;o de&nbsp;e-mail e&nbsp;outros perfis de&nbsp;usu&aacute;rio',
            text: 'Seu e-mail &eacute;&nbsp;solicitado por Uplify para confirmar seu registro e&nbsp;enviar notifica&ccedil;&otilde;es importantes.',
          },
          {
            title: 'Veja os&nbsp;detalhes de&nbsp;seu canal',
            text: 'Acesso somente leitura. Permite Uplify para otimizar a&nbsp;entrega de&nbsp;campanhas patrocinadas a&nbsp;voc&ecirc;.',
          },
          {
            title: 'Enviar bate-papos para meu canal',
            text: 'Este nos permite enviar mensagens de&nbsp;chat com um&nbsp;link para o&nbsp;site do&nbsp;patrocinador.',
          },
        ],
        redirectBtn: 'Ir para VK Play',
      },
    },
    streamer: {
      home: {
        header: {
          greeting: 'Oi',
          introduction: 'Eu sou Constantine da Uplify. Ajudarei você a conhecer melhor seus espectadores e começar a ganhar com as transmissões ao vivo.',
          letsDoIt: 'Vamos fazer isso!',
        },
        about: {
          label: 'QUEM NÓS SOMOS',
          title: 'Uplify é um serviço para integrar anúncios in-stream',
          description: 'Você alcançou o teste beta aberto para criadores que falam inglês. Conecte nosso widget e em breve você poderá começar a monetizar seus streams.',
          howItWorks: 'Veja como funciona',
          setup: 'Configurar o Uplify',
          statistic: {
            campaigns: {
              title: 'Completamos mais de',
              amount: '50 campanhas<br>de anúncios',
            },
            payouts: {
              title: 'Pagamos mais de',
              amount: 'US$ 400.000<br>para criadores',
            },
          },
          howUplifyWorks: 'Como o Uplify funciona',
        },
        next: {
          label: 'QUAL É O PRÓXIMO',
          description: 'Depois de concluir, começaremos a analisar seu público e encontraremos os anunciantes certos nas próximas duas semanas. Fique à vontade!',
          steps: {
            first: 'Visite qualquer página e <a href="https://help.uplify.app" target="_blank">leia como nosso serviço funciona →</a>',
            second: 'Junte-se à nossa comunidade de criadores em <a href="https://discord.gg/uplify" target="_blank">Discord →</a>',
            third: 'Escreva-me com todas as perguntas via chat',
          },
        },
      },
      dashboard: {
        dashboard: 'Dashboard',
        greeting: 'Bem-vindo, {name} 👋',
        setup: {
          finishYourSetup: 'Conclua sua instalação',
          stepsLeft: 'passo restante | passos restantes',
          aboutXMin: 'Cerca de {min} min',
          steps: {
            fillYourProfile: 'Preencha seu perfil',
            configureWidget: 'Configure o widget',
            connectChatbot: 'Conecte um chatbot',
            getFirstSponsorship: 'Consiga seu primeiro patrocínio',
            freemium: 'Ganhe mais com sua comunidade',
          },
          guides: {
            fillYourProfile: 'https://www.youtube-nocookie.com/embed/ykLXIguDxeA?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            configureWidget: 'https://www.youtube-nocookie.com/embed/7yTaaRcvnNQ?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            connectChatbot: 'https://www.youtube-nocookie.com/embed/TKvlptfQ0ZE?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            getFirstSponsorship: 'https://www.notion.so/Par-metros-publicit-rios-d3de7840ae284c33ba8b57bef7d2959c',
            freemium: 'https://fanatical-fly-511.notion.site/Streamer-support-page-6c5d0c3b3e62448e94aa2ce1223e4986',
          },
          stepByStepGuide: 'Guia passo-a-passo',
          closeChecklist: 'Fechar',
        },
        values: {
          estimatedEarnings: 'Rendimento estimado para&nbsp;{month}',
          turnOnUplifyPromo: 'Confira sua carteira',
          earnedByReferalls: 'Ganhado por indicações',
          getReferalLink: 'Obtenha o link de referência',
          newCampaignsComingSoon: 'Novas campanhas estão chegando',
          activeCampaignsToday: 'campanha ativa hoje | campanhas ativas hoje',
          goToCampaigns: 'Ir para campanhas',
          earnedByFreemium: 'Ganho com Uplify Link',
          freemiumLink: 'Definir um objetivo',
        },
        resources: {
          discordText: 'Junte-se ao nosso Discord Server e compartilhe sua experiência com a comunidade',
          blogText: 'Receba as últimas atualizações e dicas de streamers em nosso Blog',
          helpCenterText: 'Saiba tudo sobre o Uplify em nosso Centro de Ajuda',
        },
        advice: {
          heading: 'POR QUE ISSO É IMPORTANTE?',
          title: 'Receba a partir de $500 por mês',
          description: 'Para começar a monetizar seu canal e ter acesso aos melhores patrocinadores, você precisa completar ',
          steps: '{n} etapas | {n} etapa | {n} etapas',
          descriptionEnd: 'Comece já!',
        },
        levels: {
          heading: 'Seu crescimento',
          goals: 'Metas de nível {level}',
          perks: 'Benefícios de nível {level}',
          lvl: 'LVL',
          days: 'dias',
          discord: {
            active: 'Ingressou',
            notActive: 'Juntar',
          },
          twitch: {
            active: 'Ativo',
            notActive: 'Configurar',
          },
          advice: {
            label: 'sobre níveis',
            title: 'Nível Até para desbloquear mais benefícios',
            description: 'O Programa de Fidelidade Uplify Loyalty é uma série de metas incrementais que você pode se esforçar para alcançar enquanto estiver transmitindo em qualquer uma das Plataformas suportadas. À medida que você continuar a crescer e ganhar novos níveis, haverá diferentes características e vantagens que se tornarão disponíveis para você.',
            link: {
              text: 'Sobre os níveis',
              url: 'https://help.uplify.app/en/articles/6563236-streamer-loyalty-program',
            },
          },
        },
      },
      campaigns: {
        header: {
          title: 'Campanhas',
        },
        analytics: {
          returnBtn: 'Todas as campanhas',
          reportBtn: {
            title: 'Relatório da campanha',
            shortTitle: 'Relatório',
            loading: 'Carregando',
            noData: 'Sem dados',
          },
          fields: {
            clicks: 'Cliques',
            ctr: 'CTR',
            impressions: 'Vistas',
            botClicks: 'Cliques de fraude',
            date: 'Data',
          },
        },
        tabs: {
          current: 'Atuais',
          completed: 'Concluídas',
        },
        platforms: {
          title: 'Sua plataforma ativa atual para monetiza&ccedil;&atilde;o',
          link: {
            text: 'Mudar de plataforma',
          },
        },
        freemium: {
          title: 'Mais maneiras de ganhar',
          msg: {
            title: 'AUMENTE SUA RENDA COM UPLIFY LINK',
            text: 'Descubra a&nbsp;chave para receita recorrente, engajamento e&nbsp;relacionamentos significativos com o&nbsp;visualizador. Comece a&nbsp;ganhar at&eacute;&nbsp;70% mais convidando seus f&atilde;s para apoi&aacute;-lo agora.',
            link: {
              text: 'Configurar Uplify.link',
            },
            getAccess: 'Conseguir acesso',
          },
        },
        active: {
          title: 'Campanhas ativas',
          description: 'As suas campanhas publicitárias com estatísticas detalhadas em tempo real.',
        },
        inactive: {
          title: 'Campanhas inativas',
          description: 'De acordo com as categorias ignoradas ou desativadas por você ou moderador.',
          link: 'Escreva para o suporte se isso for um erro',
          linkHref: 'https://discord.gg/uplify',
        },
        future: {
          title: 'Próximas campanhas',
          description: 'Campanhas que estarão disponíveis em breve.',
        },
        notAvailable: {
          title: 'Campanhas indisponíveis',
          description: 'Campanhas que não estão à sua disposição, ou que são desactivadas pelo moderador. Se isto for um erro, contacte o apoio.',
        },
        advice: {
          title: 'É importante saber',
          dailyLimit: {
            title: 'Limite diário',
            description: 'o número máximo de vistas disponíveis para o usuário diariamente.',
          },
          dailyActionLimit: {
            title: 'Límite de acción diario',
            description: 'o número máximo de ações disponíveis para todos os usuários diariamente. Observe que ações que excedam os limites diários não serão pagas.',
          },
          potentialIncome: {
            title: 'Receitas potenciais',
            description: 'o valor potencial que pode ser ganho com uma campanha.',
            link: 'Como é calculado?',
          },
          ctr: {
            title: 'CTR',
            description: 'é uma medida da clicabilidade de um anúncio. Quanto maior for o número, melhor.',
            link: 'Como aumentar a CTR?',
          },
        },
        none: [
          'As campanhas publicitárias estão a chegar',
        ],
      },
      widget: {
        header: {
          title: 'Configurações',
          widget: 'Widget',
          chatbot: 'Chatbot',
          extension: 'Extensões',
          stream: 'Em Stream',
          disabled: 'OFF',
          enabled: 'ON',
        },
        widgetPlatform: {
          title: 'Plataforma de monetização',
          description: 'Escolha a plataforma onde Uplify irá exibir anúncios, coletar estatísticas e te pagar por vistas de anúncios. A escolha de uma plataforma irá afetar as campanhas publicitárias disponíveis e os módulos widget.',
        },
        widgetLink: {
          title: 'Link do Widget',
          description: 'Link para OBS ou outros programas de transmissão.',
          optionDrag: {
            label: 'Opção 1: arrastar e soltar no OBS',
            helpWithSetup: 'Guia passo-a-passo',
            warn: 'Arrastar e soltar no OBS não funcionará se o OBS estiver sendo executado como Administrador.',
            btn: 'Arraste e solte no OBS',
          },
          optionLink: {
            label: 'Option 2: Setup widget from scratch',
            helpWithSetup: 'Guia passo-a-passo',
            warn: 'Certifique-se de que o widget esteja definido para toda a área visível em seu software de transmissão e na camada superior. Verifique o guia.',
          },
          preview: 'Exibir animação de teste em software de transmissão.',
        },
        widgetDelay: {
          title: 'Atraso da transmissão',
          new: 'Novo',
          description: 'Defoma o delay correto da sua transmissão para o melhor funcionamento do chatbot e do serviço.',
          howItWorks: 'Como funciona',
          fieldLabel: 'Tempo de atraso (0 to 1800 s)',
        },
        chatbot: {
          title: 'Configuração de Chatbot',
          description: 'O chatbot é utilizado para enviar mensagens publicitárias e pesquisas.',
          surveyDescription: 'Um problema com o ambiente Nightbot ou um conflito com outro chatbot?',
          wantAnotherChatbot: 'Eu quero usar outro chatbot',
          shareChatbotSetup: 'Por favor, compartilhe conosco sua experiência sobre a configuração de chatbot.',
          goThroughTheSurvey: 'Faça a pesquisa',
          helpWithSetup: 'Guia passo-a-passo',
          connectNightbot: 'Ligar o Nightbot',
          disconnectNightbot: 'Desativar o nightbot',
          checkChatbot: 'Verifica Nightbot',
          sendMessage: 'Pré-visualização da mensagem',
          messageSent: 'Mensagem enviada',
          error: 'Antes de&nbsp;conectar o&nbsp;bot, <a href="https://nightbot.tv/dashboard" target="_blank">visite o&nbsp;site do&nbsp;nightbot</a> e&nbsp;encerre a&nbsp;sess&atilde;o de&nbsp;jogo.',
        },
        advertSettings: {
          title: 'Gerenciador de anúncios',
          description: 'Seleccionar a frequência e o modo de visualização dos anúncios.',
          moreAboutFormats: 'Saiba mais sobre formatos de anúncios',
          bannerTimeout: 'Frequência',
          adPlaybackMode: 'Modo de exibição',
          playbackModeManual: 'Manual',
          playbackModeAuto: 'Auto',
          manualPlaybackTitle: 'Lançamento de anúncio',
          pictureInPicturePosition: 'Local da imagem na fotografia',
          leaderboardPosition: 'Localização do banner amplo',
          adsBlocksCountTitle: 'Número de anúncios',
          adsBlocksCountDuration: 'Duração: {segundos} seg',
          advice: {
            title: 'Lançamento de anúncio manual',
            firstString: 'Pode executar anúncios manualmente quando o widget, extensão e chatbot estão activos.',
            secondString: 'Assim que o anúncio estiver disponível, o botão é desbloqueado.',
          },
        },
        panel: {
          title: 'Gerenciador de anúncios remotos',
          mobileVersion: {
            title: 'Gerenciador de anúncios móveis',
            description: 'Digitalize o código QR para abrir o Gerenciador de Anúncios no seu telefone. Não mostre este código a ninguém.',
          },
          obsVersion: {
            title: 'Gerenciador de anúncios para OBS',
            description: 'O Gestor de Anúncios pode ser adicionado ao OBS. Copie e cole o link em View → Docks → Custom Browser Docks. Não mostrar este hiperlink à ninguém.',
          },
        },
        ignoreCategories: {
          title: 'Categorias a ignorar',
          description: 'As campanhas de anúncios das categorias selecionadas serão automaticamente desativadas para exibição na seção de campanhas.',
        },
        attention: {
          title: 'Configuração de Widget e chatbot',
          widgetDisabled: {
            title: 'Widget desactivado',
            reasons: {
              title: 'As seguintes razões são possíveis:',
              broadcast: 'Programa de radiodifusão está desactivado',
              notSetUp: 'O widget não está configurado no programa de difusão.',
            },
          },
          chatbotDisabled: {
            title: 'Chatbot desactivado',
            reasons: {
              toBeConnected: 'O Chatbot tem de estar ligado para o canal',
              addChatbotAsModerator: 'Necessidade de adicionar o Nightbot como moderador',
            },
          },
        },
        twitchExtension: {
          title: 'Extensão do Twitch',
          description: 'Configure uma extensão para o Twitch para obter campanhas especiais de patrocínio no formato de extensão.',
          status: 'Estado da extensão',
          btns: {
            setup: 'Extensão de instalação',
            check: 'Verifique a extensão',
          },
        },
      },
      wallet: {
        header: {
          title: 'Carteira',
        },
        tabs: {
          overview: 'Visão geral',
          history: 'Histórico de pagamentos',
        },
        balance: {
          heading: 'Sua receita',
          title: 'Saldo',
          currentBalance: {
            label: 'Saldo',
            popover: 'Valor da receita confirmada',
          },
          minimumPayout: 'Valor mínimo de pagamento',
          payoutAmount: 'Montante a pagar',
          nearestDatePayout: 'Data de pagamento mais próxima',
          howPayoutsWork: 'Como é que os pagamentos funcionam?',
          referralInfo: 'Parabéns! Você ganhou um bônus de referência de {sum}. <br/><br/>Por favor, esteja ciente de que, para manter este bônus, os termos do programa exigem que você alcance um certo número de vistas até {date}. <br/><br/>Vistas: {imps} / {impsTotal}.',
          estimatedEarnings: {
            label: 'Rendimento estimado para&nbsp;{month}',
            popover: 'O&nbsp;rendimento ser&aacute; ajustado ap&oacute;s verifica&ccedil;&atilde;o de&nbsp;poss&iacute;veis fraudes',
          },
          auditedEarnings: {
            label: 'Valor em análise',
            popover: 'Valor a ser verificado',
          },
          nextPayout: 'Valor a ser pago em {date}',
        },
        paymentServices: {
          all: {
            title: 'Serviços de pagamento',
            loading: 'carregamento',
            active: 'Activo para pagamentos',
            activate: 'Método por defeito',
            learnMore: 'Saiba mais sobre as taxas',
            paypal: 'PayPal',
            wireTransfer: 'Wire transfer',
            paypalData: 'Dados da facturação',
            billingsData: 'Dados da facturação',
            whyDoWeNeedThisData: 'Porque é que precisamos destes dados?',
            setUp: 'Configurar',
          },
          razorPay: {
            title: 'Integração de pagamentos',
            loading: 'carregando',
            learnMore: 'Saiba mais sobre as taxas',
            setUp: 'Configurar',
            statuses: {
              yourStatus: 'Seu status',
              paymentMethod: 'Forma de pagamento',
              payable: 'A pagar',
              notPayable: 'Não pagável',
              notAdd: 'Não adicionar',
            },
            form: {
              title: 'Dados de faturamento',
              whyDoWeNeedThisData: 'Por que precisamos desses dados?',
            },
          },
          tochkaBank: {
            title: 'Integração de pagamentos',
            loading: 'carregando',
            learnMore: 'Saiba mais sobre as taxas',
            setUp: 'Configurar',
            employment: {
              type: 'Employment type',
              physical: 'Individual',
              physicalShort: 'Individual',
              selfEmployed: 'Self-employed',
              taxHintSelf: 'The self-employed are required to pay taxes on income in the amount of 6% of the amount of remuneration. The self-employed person pays the tax himself on a receipt from the tax office.',
              taxHintPhysical: 'Individuals are required to pay taxes on income in the amount of 13% of the amount of remuneration. We will transfer this amount to the tax ourselves.',
              selfRegisterHint: 'Para começar, conecte o parceiro de serviço “Я – сам” e certifique-se de dar permissão ao Uplify para interagir com o serviço. \n \n A integração com “Я – сам” permite efetuar pagamentos automaticamente, gerar cheques, calcular e pagar impostos sobre a renda.',
              formHint: 'Certifique-se de ter dado permissão ao Uplify para interagir com “Я – сам” antes de preencher os detalhes abaixo. \n \n Por favor, duplique os dados nos campos abaixo para verificar a integração com o parceiro de serviço.',
              status: 'Status',
              registered: 'Registered',
              notRegistered: 'Not registered',
              register: 'Conectar',
            },
            statuses: {
              yourStatus: 'Seu status',
              paymentMethod: 'Forma de pagamento',
              payable: 'A pagar',
              notPayable: 'Não pagável',
              notAdd: 'Não adicionar',
            },
            form: {
              title: 'Account linking',
              whyDoWeNeedThisData: 'Why do we need this data?',
            },
            paymentStatus: {
              lastTransaction: 'Ultimo pagamento',
              status: {
                inProgress: 'Em andamento',
                error: 'Erro',
                success: 'Sucesso',
              },
              fields: {
                amount: 'Quantia',
                date: 'Data',
                status: 'Status',
                methods: 'Métodos de Pagamento',
              },
            },
          },
        },
        analytics: {
          heading: 'Análise de receita',
          categories: {
            awareness: 'Formatos de patrocínio',
            performance: 'Formatos interativos',
            actions: 'Bônus de ação',
            freemium: 'Uplify Link',
            referrals: 'Referências',
            youtube_text: 'Youtube Text',
          },
          source: {
            title: 'Fonte de receita',
          },
        },
        payoutHistory: {
          title: 'Histórico de pagamentos',
          columns: {
            date: 'Data',
            amount: 'Montante',
            service: 'Forma de pagamento',
            invoice: 'Fatura',
          },
          loading: 'carregamento',
          noData: 'Ainda não foi efetuado nenhum pagamento.',
        },
        tipaltiAdvice: {
          title: 'Ganhe {amount} para liberar a configuração dos saques',
          description: 'A configuração de pagamento só está disponível para criadores que conseguirem 80% do pagamento mínimo',
        },
        tochkaAdvice: {
          title: 'Connect one of the payout services',
          description: 'You don\'t have any connected payout services. Connect a prefer way to receive payouts.',
        },
      },
      profile: {
        header: {
          title: 'Configurações de conta',
        },
        tier: {
          whatIsTier: 'O que é um escalão?',
        },
        agency: {
          title: 'Agência',
        },
        form: {
          howToChangeLanguage: 'Para mudar o idioma de transmissão ou o país, por favor nos escreva no chat ou no Discord.',
        },
        advice: {
          title: 'Verificação por email requerida',
          info: 'Informamo-lo apenas sobre as notícias e actualizações mais importantes.',
          noSpam: 'Sem spam.',
          clickLink: 'Por favor, clique no link no e-mail de confirmação que lhe enviámos.',
          link: 'Enviar novamente',
          linkClicked: 'Correio enviado',
        },
        platforms: {
          title: 'Conectar plataformas',
          writeUsToDetach: 'Nos escreva para desconectar',
        },
        discord: {
          title: 'Junte-se ao nosso servidor Discord para se manter atualizado, obter suporte e nivelar.',
          status: {
            caption: 'Seu status',
            connected: 'Conectado',
            notConnected: 'Não conectado',
          },
        },
        deactivation: {
          title: 'Desativa&ccedil;&atilde;o de&nbsp;conta',
          textBlock1: 'Se&nbsp;voc&ecirc; quiser uma pausa de&nbsp;Uplify, voc&ecirc; pode desativar temporariamente sua conta ao&nbsp;inv&eacute;s de&nbsp;apag&aacute;-la. Seu perfil n&atilde;o aparecer&aacute; no&nbsp;Uplify enquanto voc&ecirc; estiver fora.',
          textBlock2: 'Se&nbsp;voc&ecirc; continuar, seu perfil e&nbsp;detalhes da&nbsp;conta ser&atilde;o exclu&iacute;dos&nbsp;em <strong>{date}</strong>. Voc&ecirc; n&atilde;o ser&aacute; vis&iacute;vel em&nbsp;Uplify entre agora e&nbsp;ent&atilde;o. Se&nbsp;voc&ecirc; mudar de&nbsp;id&eacute;ia, voc&ecirc; pode voltar a&nbsp;entrar antes da&nbsp;data de&nbsp;exclus&atilde;o e&nbsp;optar por manter sua conta.',
          deactivateBtn: 'Desativar',
          drawer: {
            title: 'Desativar conta',
            subtitle: 'Voc&ecirc; tem certeza de&nbsp;que deseja desativar sua conta?',
            textBlock1: 'Quando uma conta Uplify &eacute;&nbsp;desativada, as&nbsp;seguintes a&ccedil;&otilde;es acontecem com os&nbsp;dados da&nbsp;conta:',
            textBlock2: [
              'O&nbsp;perfil, p&aacute;gina p&uacute;blica, widget est&atilde;o escondidos da&nbsp;plataforma, e&nbsp;n&atilde;o ser&atilde;o mais vis&iacute;veis para outros usu&aacute;rios.',
              'Qualquer tipo de&nbsp;campanha n&atilde;o estar&aacute; dispon&iacute;vel',
              'O&nbsp;saldo estar&aacute; dispon&iacute;vel at&eacute; que a&nbsp;conta seja exclu&iacute;da e&nbsp;s&oacute;&nbsp;ser&aacute; pago quando o&nbsp;limite m&iacute;nimo for atingido.',
              'Uplify manter&aacute; os&nbsp;dados da&nbsp;conta enquanto ela estiver desativada. O&nbsp;propriet&aacute;rio da&nbsp;conta pode reativar a&nbsp;conta a&nbsp;qualquer momento durante este per&iacute;odo.',
              'Se&nbsp;a&nbsp;conta n&atilde;o for reativada dentro de&nbsp;um&nbsp;determinado per&iacute;odo de&nbsp;tempo, Uplify pode apagar os&nbsp;dados da&nbsp;conta permanentemente. O&nbsp;Uplify afirma que este processo pode demorar at&eacute; 40&nbsp;dias.',
              'Uplify tamb&eacute;m declara que eles podem reter certas informa&ccedil;&otilde;es de&nbsp;contas desativadas se&nbsp;for necess&aacute;rio cumprir com obriga&ccedil;&otilde;es legais, resolver disputas, prevenir fraudes ou&nbsp;aplicar suas pol&iacute;ticas.',
            ],
            textBlock3: '&Eacute;&nbsp;importante observar que desativar uma conta n&atilde;o &eacute;&nbsp;o&nbsp;mesmo que apagar uma conta, e&nbsp;&eacute;&nbsp;um&nbsp;processo revers&iacute;vel, onde a&nbsp;conta e&nbsp;seus dados podem ser restaurados.',
            confirmBtn: 'Confirme',
            cancelBtn: 'Cancelar',
          },
        },
      },
      referrals: {
        header: {
          title: 'Programa "Indique um amigo',
        },
        unavailable: 'Infelizmente, o Programa de Indicação não está disponível para você ou na sua região. <br>Caso tenha alguma dúvida, fale conosco no <a href="https://discord.gg/uplify" target="_blank">Discord</a> ou Intercom.',
        invite: {
          title: 'Convidar amigos',
          description: 'Receba {referrer} por cada amigo que convidar, e o seu amigo receberá {referral} depois de completar a inscrição e mostrar os primeiros anúncios.',
          invited: 'Convidado',
          reward: 'Recebido dos convites',
          link: 'Link de convite',
          advice: {
            title: 'Como é que sou pago?',
            description: 'Receberá um pagamento quando um amigo se inscrever para Uplify, confirmar o e-mail, e mostrar anúncios para 5.000 vistas.',
            link: {
              label: 'Termos do programa Recomendar a um amigo',
              url: '/',
            },
          },
        },
        promotion: {
          title: 'Promover na sua livestream',
          switchLabel: 'Promova Uplify na sua livestream.',
          advice: {
            title: 'Como funciona:',
            description: 'Pode promover o nosso serviço na sua livestream. Receberá {referrer} e as suas referências receberão {referral} após completar todos os termos. <br/><br/>A cada duas horas, a Uplify envia um banner personalizado durante a transmissão ao vivo, pedindo ingressar em nosso serviço.',
          },
          link: {
            label: 'Leia mais sobre promoção',
            url: '/',
          },
          demo: {
            title: 'Clique para ver a pré-visualização de um banner no software de difusão.',
          },
        },
        panels: {
          title: 'Painéis de contração',
          description: 'Coloque um banner debaixo do seu player para convidar mais usuários.',
        },
        history: {
          title: 'História do convite',
          none: 'Ainda não convidou ninguém :(',
          columns: {
            creator: 'Referência',
            date: 'Data de adesão',
            status: 'Estado',
            impressions: 'Vistas publicitárias',
          },
          paid: 'Paga',
          waiting: 'Espera',
        },
        updated: {
          invite: {
            title: 'Convidar amigos',
            description: 'Receba {referrer} por cada amigo que convidar, e o seu amigo receberá {referral} depois de completar a inscrição e mostrar os primeiros anúncios.',
            invited: 'Convidado',
            reward: 'Recebido dos convites',
            link: 'Link de convite',
            advice: {
              title: 'Como é que sou pago?',
              description: 'Você pode promover o Uplify na sua transmissão ao vivo.Você ganhará 5% da renda de todos os cretores que você se referir. <br/><br/>A cada duas horas, a Uplify envia um banner personalizado durante a transmissão ao vivo, pedindo ingressar em nosso serviço.',
              link: {
                label: 'Termos do programa Recomendar a um amigo',
                url: '/',
              },
            },
          },
          promotion: {
            title: 'Promover na sua livestream',
            switchLabel: 'Promova Uplify na sua livestream.',
            advice: {
              title: 'Como funciona:',
              description: 'Pode promover o nosso serviço na sua livestream. Receberá {referrer} e as suas referências receberão {referral} após completar todos os termos.',
            },
            link: {
              label: 'Leia mais sobre promoção',
              url: '/',
            },
            demo: {
              title: 'Clique para ver a pré-visualização de um banner no software de difusão.',
            },
          },
          history: {
            advice: {
              title: 'É importante saber',
              description: '<strong>Convidado</strong>: o&nbsp;usu&aacute;rio se&nbsp;registrou, mas n&atilde;o concluiu a&nbsp;integra&ccedil;&atilde;o. <br/><br/><strong>Integrado</strong>: o&nbsp;usu&aacute;rio concluiu a&nbsp;integra&ccedil;&atilde;o e&nbsp;est&aacute; pronto para exibir campanhas patrocinadas.',
            },
            invited: 'Convidado',
            hasImpressions: 'Integrado',
          },
        },
      },
      referralCheck: {
        title: 'Seu link de referência funciona muito bem',
        text: 'Seus amigos podem se inscrever e receber um bônus. <br><a href="https://discord.gg/uplify" target="_blank">Entre em contato conosco</a> se tiver alguma dúvida.',
        btnText: 'Voltar para casa',
      },
      extension: {
        header: {
          title: 'Extensão do Twitch',
        },
        about: {
          title: 'Sobre',
          description: 'Obtenha todos os links para o seu conteúdo em um só lugar. Com os blocos, você pode vincular suas lojas, promoções e redes sociais. Crie seu próprio design de bloco exclusivo, adicione links e pronto.',
          status: 'Status da extensão',
          statusTrue: 'Todo o trabalho',
          statusFlase: 'Não está funcionando',
        },
        settings: {
          title: 'Configurações',
          description: 'Crie seu próprio design de bloco exclusivo e adicione-o ao painel.',
          form: {
            block: {
              title: 'Bloco',
              type: {
                label: 'Tipo de bloco',
                placeholder: 'Selecione o tipo de bloco',
              },
              link: {
                label: 'Ir para o link',
                placeholder: 'Colar link',
              },
              deleteBlock: 'Excluir bloco',
            },
            addBlock: 'Adicionar mais um bloco',
          },
        },
        preview: {
          label: 'Visualização do painel',
          title: 'Minha árvore de links',
          action: 'Toque para avançar',
        },
      },
      freemium: {
        title: 'Uplify Link',
        tabs: {
          setup: 'Configuração de widget',
          profile: 'Perfil',
          alerts: 'Alertas de Livestream',
          supporters: 'Análise',
          posts: 'Postagens',
        },
        posts: {
          addNewBtn: 'Adicione nova postagem',
          post: {
            createdAt: 'Postado em',
            edit: 'Editar',
            remove: 'Remove',
          },
          sidebar: {
            title: {
              add: 'Adicione nova postagem',
              edit: 'Post de edição',
            },
            markdown: {
              label: 'Texto de marcação',
              hint: 'Por exemplo:<br><br>Eu simplesmente amo **<b>texto em negrito</b>**.<br>Também *<i>texto em itálico</i>* é muito legal.<br><br>Meu link favorito é [Uplify.link](https://uplify.link).',
            },
            embed: {
              label: 'Link de incorporação',
              hint: 'Some text',
            },
            btns: {
              post: 'Publicar',
              submit: 'Salvar',
            },
          },
        },
        setup: {
          link: {
            title: 'Sua página Uplify Link',
            description: 'Adicione este link à sua mídia social e compartilhe com sua comunidade. Ganhe mais compartilhando seu link regularmente.',
            errors: {
              unique: 'Criador com este nome já existe',
              general: 'Nome inválido',
            },
          },
          goal: {
            title: 'Definir um objetivo',
            description: 'Os objetivos geralmente captam a atenção e motivam seus telespectadores. Adicione um objetivo e deixe que sua comunidade o ajude a alcançá-lo.',
            addGoal: 'Adicionar objetivo',
            reachedOf: '100 de 2.000 alcançados!',
          },
          goalForm: {
            title: 'Criar um novo objetivo',
            editTitle: 'Edição de objetivo',
            fields: {
              title: {
                caption: 'Título do objetivo',
                placeholder: 'por exemplo, nova webcam',
              },
              description: {
                caption: 'Descrição do objetivo',
                placeholder: 'Quando eu chegar a 200 pontos, comprarei uma nova webcam para mover meus vivestreams para um novo nível.',
                hint: 'Faça uma breve descrição das razões pelas quais você precisa atingir seu objetivo.',
              },
              amount: {
                caption: 'Valor do objetivo',
                hint: 'Defina uma quantia sem o sinal de moeda, por exemplo, 100. O progresso da meta será mostrado como uma porcentagem.',
              },
              progress: {
                caption: 'Progresso até o momento',
                hint: 'Insira a quantia que você já levantou para este objetivo. Digite 0 se você estiver começando do zero.',
              },
              publicAmount: {
                caption: 'Mostrar publicamente a quantidade alvo',
                hint: 'Mostrar o valor total da meta em sua página pessoal.',
              },
            },
            statusMessage: {
              success: 'O objetivo foi atualizado',
              fail: 'Erro de atualização do objetivo',
            },
          },
          poll: {
            title: 'Definir uma nova pesquisa',
            description: 'As pesquisas ajudam a envolver melhor seu público, o que simultaneamente aumentará sua taxa de engajamento e seus ganhos. Os telespectadores devem assistir aos anúncios antes de votar, e você obterá receita com os anúncios que mostrar.',
            addPoll: 'Adicionar pesquisa',
            duration: 'Duração',
            votes: 'Votos - {num} restantes',
          },
          pollForm: {
            title: 'Criar uma nova pesquisa',
            editTitle: 'Edição de pesquisa',
            responsesTitle: 'Respostas',
            responsesTitleMin: '(mínimo 2)',
            fields: {
              question: {
                caption: 'Pergunta',
                placeholder: 'por exemplo, que jogo devo jogar a seguir?',
              },
              answer: {
                caption: 'Resposta',
              },
              condition: {
                caption: 'Modo de votação',
                hint: 'Condição de rescisão: por tempo ou número de votos',
                duration: 'Atas',
                maxVotes: 'Votos',
              },
              duration: {
                caption: 'Duração em minutos',
                hint: 'O tempo de validade da sua pesquisa.',
              },
              maxVotes: {
                caption: 'Meta de votos',
                hint: 'A votação terminará quando atingir este número de votos',
              },
            },
            statusMessage: {
              success: 'Pesquisa foi atualizado',
              fail: 'Erro de atualização do pesquisa',
            },
          },
        },
        profile: {
          telegram: {
            title: 'Repositório automático',
            description: 'Conecte sua rede social e&nbsp;as&nbsp;postagens aparecer&atilde;o automaticamente em&nbsp;sua p&aacute;gina Uplify Link.',
            input: {
              title: 'Telegram channel',
              description: 'Adicione um&nbsp;link para seu canal com @ e&nbsp;atribua nosso bot @UplifyBot como administrador.',
              label: 'Link',
              placeholder: '@my_channel',
            },
            errors: {
              channelExists: 'Não há canal com este nome',
              invalidName: 'O nome de canal errado',
            },
          },
          about: {
            title: 'Sua biografia',
            description: 'Adicione detalhes para o seu perfil',
            placeholder: 'Apenas algumas palavras',
            preview: 'Visualização:',
            advice: {
              label: 'DICA',
              title: 'Você pode usar Markdown',
              description: 'Por exemplo:<br><br>Eu simplesmente amo **<b>texto em negrito</b>**.<br>Também *<i>texto em itálico</i>* é muito legal.<br><br>Meu link favorito é [Uplify.link](https://uplify.link).',
            },
            ai: {
              label: 'Texto gerado por IA para o seu perfil',
              delete: 'Excluir',
              edit: 'Editar',
              like: 'Como',
              suggestionFull: 'Aqui está o que nossa IA pensa sobre {name}',
              suggestionEmptyMale: '{name} no escribió nada sobre sí mismo, pero esto es lo que nuestra IA puede decir',
              suggestionEmptyFemale: '{name} no escribió nada sobre sí misma, pero esto es lo que nuestra IA puede decir',
            },
          },
          gear: {
            title: 'Engrenagens e Configuração',
            description: 'Diga ao&nbsp;seu p&uacute;blico que tipo de&nbsp;equipamento de&nbsp;streaming voc&ecirc; usa',
            form: {
              addBlock: 'Adicionar mais um bloco',
              deleteBlock: 'Excluir bloco',
              block: {
                title: 'Equipamento',
                category: {
                  label: 'Categoria',
                  placeholder: 'Especifique uma categoria',
                },
                description: {
                  label: 'Descrição',
                  placeholder: 'Descreva o equipamento',
                },
                link: {
                  label: 'Link',
                  placeholder: 'Fornecer um link',
                },
                sku: {
                  label: 'SKU',
                  placeholder: '',
                },
              },
            },
            advice: {
              label: 'Dica',
              title: 'Widget Yandex.Market',
              text: 'Verifique a&nbsp;disponibilidade dos produtos no&nbsp;Yandex.Market. Se&nbsp;pelo menos um&nbsp;estiver faltando, um&nbsp;widget com ofertas semelhantes ser&aacute; mostrado no&nbsp;lugar dos seus produtos.',
            },
          },
          social: {
            title: 'Links sociais',
            description: 'Compartilhe links de redes sociais com seu público',
            form: {
              addBlock: 'Adicionar mais um bloco',
              deleteBlock: 'Excluir bloco',
              block: {
                title: 'Ligação',
                category: {
                  label: 'Rede social',
                  placeholder: 'Selecione a rede social',
                },
                description: {
                  label: 'URL',
                  placeholder: 'Colar link',
                },
              },
            },
          },
          theme: {
            title: 'Tema de cores',
            description: 'Escolha um tema de cores para sua página Uplify Link',
            select: {
              label: 'Tema de cores',
              options: {
                light: 'Luz',
                dark: 'Escuro',
              },
            },
          },
          banner: {
            title: 'Banner de perfil',
            description: 'Personalize o banner do seu perfil na página pessoal',
            label: 'Carregue um PNG, JPG com menos de 5 MB. O tamanho da imagem deve ser de pelo menos 1376 x 300 px.',
          },
        },
        alerts: {
          supportAlert: 'Alerta de apoio',
          goalAlert: 'Alerta de objetivost',
          pollAlert: 'Alerta de votação',
          chatAlert: 'Alerta de chat',
          preview: 'Pré-visualização',
          alertPreview: {
            title: 'Visualização de alerta',
            description: 'Configure seu widget em seu software OBS (OBS Studio, Twitch Studio ou Xsplit) e teste os alertas antes da transmissão ao vivo.',
            sendTest: 'Enviar visualização',
          },
          chatBotReminder: 'Requer a criação de um chat bot',
          fields: {
            messageDuration: {
              caption: 'Tempo de exibição dos alertas',
              hint: 'Por quanto tempo um alerta será exibido na transmissão ao vivo.',
            },
            supportAlertPosition: {
              caption: 'Posição de alerta de apoio',
              hint: 'Especifica onde na tela será exibido o alerta.',
            },
            goalAlertPosition: {
              caption: 'Posição de alerta de metas',
              hint: 'Especifica onde na tela será exibido o alerta.',
            },
            pollAlertPosition: {
              caption: 'Posição de alerta de pesquisa',
              hint: 'Especifica onde na tela será exibido o alerta.',
            },
            chatAlert: {
              caption: 'Habilitar alertas de bate-papo',
              hint: 'Especifica se devem ou não ser mostradas notificações interativas durante uma transmissão ao vivo.',
            },
          },
          statusMessage: {
            success: 'Pesquisa foi atualizado',
            fail: 'Erro de atualização do pesquisa',
          },
        },
        supporters: {
          stats: {
            supporters: 'Apoiadores mensais',
            revenueDay: 'Receita estimada hoje',
            cpm: 'Preço médio por 1.000 visualizações (CPM)',
            points: 'Pontos mensais',
            revenueMonth: 'Receita mensal estimada',
            impressions: 'Impressões entregues',
          },
          history: {
            supporters: 'Apoiadores',
            supportedYou: 'o apoiaram!',
            noSupportersTitle: 'Você ainda não tem nenhum apoiador',
            noSupportersMessage: 'Incentive seus telespectadores a visitar sua página para obter os primeiros apoios',
            impressions: '{imps} vistas de anúncios',
            targets: {
              today: 'Hoje',
              yesterday: 'Ontem',
              week: 'Essa semana',
              month: 'Este mês',
              allTime: 'O tempo todo',
            },
            columns: {
              id: '#',
              name: 'Nome',
              contribution: 'Pontos',
            },
            cacheTimeLeft: 'Esta classificação será atualizada em {min} min.',
          },
        },
        widgetPosition: {
          leftTop: 'Canto superior esquerdo',
          rightTop: 'Canto superior direito',
          leftBottom: 'Canto inferior esquerdo',
          rightBottom: 'Canto inferior direito',
          disabled: 'OFF',
        },
        chatbotMsgInterval: {
          every15mins: 'A cada 15 minutos',
          every30mins: 'A cada 30 minutos',
          everyHour: 'Toda hora',
          disabled: 'OFF',
        },
      },
      deactivated: {
        title: 'Sua conta está desativada',
        headline: 'Seu perfil e&nbsp;detalhes de&nbsp;conta ser&atilde;o ocultados at&eacute; <strong>{date}</strong> e&nbsp;exclu&iacute;dos nessa data.',
        textBlock1: 'Sua conta Uplify est&aacute; desativada, o&nbsp;que isso significa?',
        textBlock2: [
          'O&nbsp;perfil, p&aacute;gina p&uacute;blica, widget est&atilde;o escondidos da&nbsp;plataforma, e&nbsp;n&atilde;o ser&atilde;o mais vis&iacute;veis para outros usu&aacute;rios.',
          'Qualquer tipo de&nbsp;campanha n&atilde;o estar&aacute; dispon&iacute;vel',
          'O&nbsp;saldo estar&aacute; dispon&iacute;vel at&eacute; que a&nbsp;conta seja exclu&iacute;da e&nbsp;s&oacute;&nbsp;ser&aacute; pago quando o&nbsp;limite m&iacute;nimo for atingido.',
          'Uplify manter&aacute; os&nbsp;dados da&nbsp;conta enquanto ela estiver desativada. O&nbsp;propriet&aacute;rio da&nbsp;conta pode reativar a&nbsp;conta a&nbsp;qualquer momento durante este per&iacute;odo.',
          'Se&nbsp;a&nbsp;conta n&atilde;o for reativada dentro de&nbsp;um&nbsp;determinado per&iacute;odo de&nbsp;tempo, Uplify pode apagar os&nbsp;dados da&nbsp;conta permanentemente. O&nbsp;Uplify afirma que este processo pode demorar at&eacute; 40&nbsp;dias.',
          'Uplify tamb&eacute;m declara que eles podem reter certas informa&ccedil;&otilde;es de&nbsp;contas desativadas se&nbsp;for necess&aacute;rio cumprir com obriga&ccedil;&otilde;es legais, resolver disputas, prevenir fraudes ou&nbsp;aplicar suas pol&iacute;ticas.',
        ],
        revokeBtn: 'Revoke',
      },
    },
    partner: {
      campaigns: {
        header: {
          title: {
            'brand-awareness': 'Campanhas de patrocínio',
            performance: 'Campanhas interativas',
            preroll: 'Campanhas preroll',
          },
        },
        balance: 'Equilíbrio',
        tabs: {
          'brand-awareness': 'Conhecimento',
          performance: 'Interactive',
          preroll: 'Preroll',
        },
        types: {
          campaign: 'Campanhas',
          group: 'Grupos',
          creative: 'Criativos',
        },
        tables: {
          actions: {
            downloadReport: 'Descarregar Relatório',
            viewReport: 'Veja o relatório',
            duplicate: 'Duplicado',
            remove: 'Remover',
          },
          columns: {
            name: 'Nome',
            state: 'Estado',
            disabledStatusReason: {
              closed: 'Entre em contato com o suporte para ativar',
              parentIsDisabled: 'Pai deficiente',
            },
            clicks: 'Cliques',
            ctr: 'CTR',
            impressions: 'Vistas',
            reach: 'Alcance',
            id: 'ID',
            relatedCampaign: 'Campanha relacionada',
            relatedGroup: 'Grupo relacionado',
            format: 'Formato',
            budget: 'Orçamento',
            budgetUsed: 'Orçamento utilizado',
            totalBudget: 'Orçamento total',
            advertiser: 'Anunciante',
            startedAt: 'Começou em',
            endedAt: 'Terminou em',
            platform: 'Plataforma',
            moderation: 'Moderação',
            moderationStatus: {
              pending: 'Pendente',
              complete: 'Aprovado',
            },
            filterLabel: 'configuração',
            channels: 'Channels',
            daysRemaining: 'Dias restantes',
            externalId: 'ID Externo',
            bid_cap: 'Bid cap',
            bid_cpa: 'Bid CPA',
            actions: 'Actions',
          },
          noCampaigns: 'Não tem campanhas',
          noGroups: 'Não tem grupos',
          noCreatives: 'Não tem criativos',
        },
        newCreative: {
          title: 'Novo criativo',
          description: 'Selecione o grupo ao qual o creativo pertencerá',
        },
        newGroup: {
          title: 'Novo grupo',
          description: 'Selecione a campanha à qual o grupo pertencerá',
        },

      },
      balance: {
        header: {
          title: 'Saldo',
        },
        info: {
          title: 'Saldo atual',
          howFundUpBalance: 'Como completar o equilíbrio?',
        },
        history: {
          title: 'Histórico de pagamentos',
          table: {
            columns: {
              date: 'Data',
              amount: 'Montante',
              service: 'Serviço',
            },
          },
          noPayments: 'Ainda sem pagamentos',
        },
      },
      creators: {
        header: {
          title: 'Agência',
        },
        tabs: {
          overview: 'Visão geral',
          history: 'Histórico de transações',
          campaignsActive: 'Campanhas',
          campaignsClosed: 'Concluído',
          creators: 'Criadores',
          billing: 'Faturamento',
        },
        invite: {
          title: 'Convidar criadores',
          invited: 'Criadores convidados',
          amount: 'Montante a ser pago',
          link: 'Link do convite',
          advice: {
            title: 'Obtenha uma parte da receita',
            description: 'Gere participação na receita de seus criadores que você indicar enquanto eles estiverem ativos no Uplify.',
            link: {
              url: '/',
              label: 'Como funciona',
            },
          },
        },
        creatorsList: 'Lista de criadores',
        billing: {
          companyInformation: {
            title: 'Informações sobre a empresa',
            name: {
              label: 'Nome',
              placeholder: 'Nome da empresa',
            },
            email: {
              label: 'E-mail',
              placeholder: 'email@email.com',
            },
            address: {
              label: 'Endereço eletrônico',
              placeholder: 'Endereço da empresa',
            },
            phone: {
              label: 'Telefone para contato',
              placeholder: '+1 (123) 456 789',
            },
          },
          paymentDetails: {
            title: 'Detalhes do pagamento',
            bankName: {
              label: 'Nome do banco',
              placeholder: '',
            },
            bankAccountName: {
              label: 'Nome da conta bancária',
              placeholder: '',
            },
            bankAccountHolderAddress: {
              label: 'Endereço do titular da conta bancária',
              placeholder: '',
            },
            bankAddress: {
              label: 'Endereço do banco',
              placeholder: '',
            },
            bankAccountNumber: {
              label: 'Número da conta bancária',
              placeholder: '',
            },
            BIC: {
              label: 'Código BIC / SWIFT',
              placeholder: '',
            },
            routingNumber: {
              label: 'Número de registro',
              placeholder: '',
            },
          },
          invoices: {
            title: 'Faturas',
            date: 'Data da fatura',
            amount: 'Valor da fatura',
            invoice: 'Fatura',
          },
        },
        history: {
          title: 'Histórico de transações',
          payments: 'Transações para sempre',
          none: 'Não há transações',
          columns: {
            date: 'Encontro',
            payment: 'Pagamento por dia',
            details: 'Detalhes',
          },
          detailed: {
            title: 'Detalização de transações',
            description: 'Criadores que trouxeram dinheiro naquele dia.',
          },
        },
        list: {
          title: 'Lista de criadores',
          description: 'Lista de criadores que você convidou. Pagamentos para sempre ',
          none: 'Não há criadores',
          columns: {
            creator: 'Criadora',
            lastActivity: 'Último dia de atividade',
            earnings: 'Ganhos',
          },
        },
        creatorsPayout: {
          title: 'Pagamento aos criadores',
          description: 'Insira o valor que seus criadores devem receber quando patrocinarem sua campanha. Especifique o custo por mil vistas (CPM).',
          link: {
            text: 'Mais sobre formatos criativos',
            href: 'https://help.uplify.app/en/articles/5640856-in-stream-campaign-formats',
          },
          thirdParty: {
            checkbox: 'Permitir que parceiros terceirizados façam patrocínios',
            popover: {
              label: 'Dica',
              text: 'Insira o valor básico que inclui sua comissão e pagamento aos criadores. Especifique o custo por mil vistas (CPM).',
            },
          },
          comission: {
            label: 'Comissão',
            popover: {
              label: 'Dica',
              text: 'Usado para campanhas com acordos individuais com patrocinadores ou se os pagamentos aos criadores não estiverem configurados.',
            },
          },
          darkMarket: {
            checkbox: 'Permitir aumento da taxa para clientes do mercado negro',
            popover: {
              label: 'Dica',
              text: 'Insira um valor base para clientes da categoria do mercado negro (jogos de azar, cassino, criptografia, etc.). Especifique o custo por mil vistas (CPM).',
            },
          },
          cpaLabel: 'Pagamento aos criadores',
          darkMarketLabel: 'Preços de terceiros (incl. taxa)',
          fields: {
            video: 'Overlay 50%',
            custom: 'Custom',
            pip: 'Overlay 15%',
            interactive: 'Interativo',
            preroll: 'Pre-Roll',
            cpa: 'CPA cost',
            cpc: 'CPC cost',
          },
        },
        creatorsTable: {
          columns: {
            creators: 'Criador',
            lastActivity: 'Ultima atividade',
            balance: 'Saldo',
            campaign: 'Campanhas',
          },
          status: {
            checkList: {
              label: 'Integração em andamento',
              text: 'O streamer ainda não concluiu o processo de integração.',
            },
            payable: {
              label: 'Verifique as configurações de pagamento',
              text: 'O streamer ainda não configurou o pagamento.',
            },
            lowCtr: {
              label: 'CTR baixa',
              text: 'Problemas com CTR em campanhas.',
            },
          },
          none: 'Sem dados',
        },
        settings: {
          title: 'Configurações',
          custom: {
            headline: 'Pagamento personalizado',
            descr: 'Insira o valor personalizado que {streamer} deve receber ao fazer um patrocínio. Especifique o custo por mil vistas (CPM).',
          },
          darkMarket: {
            headline: 'Taxa para clientes do mercado escuro',
          },
          cpaLabel: 'Pagamento aos criador',
          darkMarketLabel: 'Preços de terceiros (incl. taxa)',
          fields: {
            video: 'Overlay 50%',
            custom: 'Custom',
            pip: 'Overlay 15%',
            interactive: 'Interativo',
          },
        },
        stats: {
          columns: {
            campaign: 'Campanha',
            creator: 'Criador',
            income: 'Renda',
            views: 'Vistas',
            avgCtr: 'Méd. CTR',
            ctr: 'CTR',
            status: 'Status',
          },
          none: 'Sem dados',
        },
        campaignCreators: {
          title: 'Relatório de campanha: {title}',
        },
        creatorCampaigns: {
          title: '{streamer} campanhas',
        },
        categoriesStopList: {
          title: 'Lista de parada de categorias',
          description: 'As campanhas das categorias selecionadas serão automaticamente desativadas para exibição na seção de anúncios in-stream para seus criadores.',
        },
        campaigns: {
          active: {
            title: 'Campanhas ativas',
            description: 'Lista de campanha disponível para seus criadores',
          },
          closed: {
            title: 'Campanhas fechadas',
            description: 'Lista de campanhas fechadas',
          },
          reportBtn: {
            full: 'Relatório da campanha',
            short: 'Relatório',
          },
          none: 'As campanhas publicitárias estão a chegar',
        },
      },
      campaign: {
        defaultName: 'Nome da campanha',
        defaultNameNew: 'Nova Campanha',
        group: 'Grupo',
        loading: 'Campaign loading ..',
        type: {
          title: 'Tipo de campanha',
          description: 'Escolha o tipo de campanha que se adequa aos seus objectivos de marketing.',
          comingSoon: 'Em breve',
        },
        types: {
          awareness: {
            title: 'Consciência da marca',
            description: 'Custo fixo do anúncio com base no modelo CPM',
          },
          brandLift: {
            title: 'Elevação de Marca',
            description: 'Investigação que mede o nível de percepção da marca ou do produto',
          },
          task: {
            title: 'Tarefa',
            description: 'Atribuição com um custo individual e envolvimento directo da serpentina',
          },
          performance: {
            title: 'Interactive',
            description: 'Custo de anúncio flexível com base no modelo CPA',
          },
        },
        settings: {
          title: 'Configurações da campanha',
          advice: {
            title: 'Que dados são estes?',
            name: {
              title: 'Nome da campanha',
              description: 'que aparecerão de forma diferente das serpentinas durante a emissão.',
            },
            currency: {
              title: 'A moeda',
              description: 'na qual a colocação da sua campanha será cobrada.',
            },
          },
          form: {
            name: {
              label: 'Nome da campanha',
              placeholder: 'Promoção de Primavera, por exemplo',
            },
            description: {
              label: 'Descrição da campanha',
              placeholder: 'Descrever campanha',
            },
            externalId: {
              label: 'External ID',
              placeholder: 'Por exemplo, PF-CMP-1661693993',
            },
            category: {
              label: 'Categoria da campanha',
              placeholder: 'Seleccione a categoria',
              noData: 'Sem categorias',
            },
            currency: {
              label: 'Moeda',
              placeholder: 'Seleccione a moeda',
            },
            schedule: {
              startedAtLabel: 'Data de início',
              endedAtLabel: 'Data de conclusão',
            },
            holding: {
              label: 'Participação do anunciante',
              placeholder: 'Seleccione a exploração',
            },
            advertiser: {
              label: 'Anunciante',
              placeholder: 'Seleccionar anunciante',
              noData: 'Sem dados',
            },
            status: {
              label: 'Status',
            },
            awareness: {
              description: {
                label: 'Awareness',
                placeholder: 'Describe campaign for streamers',
              },
            },
            brandlift: {
              description: {
                label: 'Brand Lift',
                placeholder: 'Describe campaign for streamers',
              },
            },
            timezone: {
              label: 'Fuso horário',
              placeholder: 'Fuso horário',
            },
            formSendStatus: {
              updated: 'A campanha foi atualizada',
            },
          },
        },
        analytics: {
          title: 'Configurações avançadas',
          affiliateNetworks: {
            label: 'Rede',
            notSelected: 'Sem integrações',
          },
          urlParams: {
            description: 'Parâmetros adicionais para a URL que permitem acompanhar o desempenho de suas campanhas.',
            paramBlock: 'Parâmetro',
            param: 'Parâmetro ',
            name: 'Valor',
            addParam: 'Adicionar outro parâmetro',
            deleteParam: 'Eliminar parâmetro',
          },
          pixel: {
            description: 'Uplify Pixel ajuda você a rastrear a atividade do usuário no site do anunciante para entregar anúncios de forma eficaz.',
            label: 'Uplify Pixel',
            btn: 'Cópia de',
          },
        },
      },
      group: {
        defaultName: 'Nome do grupo',
        defaultNameNew: 'Novo Grupo',
        loading: 'Group loading ..',
        settings: {
          title: 'Configurações de grupo',
          description: 'Agrupe as suas campanhas por parâmetros e estabeleça objectivos para seguir cada uma delas de forma eficiente',
          form: {
            name: {
              title: 'Nome do grupo',
              label: 'O nome é visível apenas para si',
              placeholder: 'Grupo',
            },
            description: {
              title: 'Descrição do grupo',
              label: 'Descrição do grupo',
              placeholder: 'Descrever o grupo para o autor',
            },
            altName: {
              addBtn: 'Adicionar nome alternativo para streamers',
              label: 'Nome do grupo para streamers',
              placeholder: 'Por exemplo, Spring Promo',
            },
            externalId: {
              title: 'External ID',
              placeholder: 'Por exemplo, PF-CMP-1661693993',
            },
            platform: {
              title: 'Plataforma',
            },
            format: {
              title: 'Formato',
              moreAbout: 'Saiba mais sobre formatos criativos',
              formats: {
                fullscreen: {
                  label: 'Vídeo em ecrã inteiro',
                  description: 'Rollo médio colocado Em Stream em ecrã inteiro',
                },
                smallVideo: {
                  label: 'Vídeo numa janela separada',
                  description: 'Rollo médio colocado em fluxo vivo no ecrã 1/4',
                },
                custom: {
                  label: 'Solução personalizada',
                  description: 'Criativo multi-formato num fluxo ao vivo em qualquer área visível do ecrã',
                },
              },
            },
            schedule: {
              title: 'Horário',
              startedAtLabel: 'Data de início',
              endedAtLabel: 'Data de conclusão',
            },
            payType: {
              payPerImpression: 'Pagamento por impressão',
              payPerAction: 'Pagamento por ação',
            },
            budget: {
              title: 'Orçamento',
              costPerUnitLabel: 'Custo da publicidade por unidade',
              impressionsLabel: 'Número de vistas',
              totalBudget: 'Orçamento total',
              costPerActionLabel: 'O custo de uma ação',
              budgetLabel: 'Orçamento',
              totalActions: 'Número esperado de ações:',
              bidCap: 'Custo por milha, CPM',
              impressions: 'Impressões',
              cpc: 'Custo do clique',
              clicks: 'Número de cliques',
              conversions: 'Número de conversões',
              margin: 'Margem da Uplify',
              agencyCommission: 'Comissão da agência',
              cpmPercent: 'Divisão do orçamento, CPM',
              cpa: 'Custo de conversão',
              creatorsPayout: 'Pagamento de criadores',
              conversion: 'Número de conversões',
              infoMessage: 'Apenas para uso interno',
              dailyClickCap: 'Limite de cliques diários',
              dailyConversionCap: 'Limite de conversão diária',
              creatorsCPM: 'Pagamento de criadores de conteúdo por CPM',
              creatorsCPA: 'Pagamento de criadores por conversão',
            },
            targets: {
              title: 'Alvos',
              ctrLabel: 'CTR alvo',
              cpaLabel: 'CPA alvo',
            },
            frequency: {
              title: 'Frequência',
              standard: {
                label: 'Norma',
                description: 'Padrão - Frequência recomendada de vistas com óptimo alcance: Adequado para campanhas a longo prazo',
              },
              accelerated: {
                label: 'Acelerado',
                description: 'Acelerado - Frequência de vistas com alcance máximo: adequado para campanhas de curto prazo',
              },
              customizable: {
                label: 'Personalizável',
                description: 'Personalizável - frequência de vistas com base em parâmetros específicos de alcance: adequado para campanhas não-padronizadas',
              },
              impressionsCount: 'Número de inserções',
              period: 'Period',
            },
            status: {
              label: 'Status',
            },
            timezone: {
              label: 'Fuso horário',
              placeholder: 'Fuso horário',
            },
            formSendStatus: {
              updated: 'O grupo foi atualizado',
            },
          },
        },
        targeting: {
          title: 'Segmentação',
          addition: {
            onlySelected: 'apenas seleccionada',
            exceptSelected: 'todos, excepto os seleccionados',
            excludeSelected: 'Excluir categorias seleccionadas da segmentação',
          },
          evaluation: {
            label: 'Avaliação do público',
            description: 'Seleccionar a língua de emissão para obter uma estimativa preliminar da audiência.',
            size: 'Tamanho',
            reach: 'Alcance',
            streamersNumber: 'Número de serpentinas',
            howCalculated: 'Como são calculados os indicadores?',
          },
          form: {
            agencies: {
              label: 'Seleção de rede',
              placeholder: 'Selecionar agência',
              warning: {
                title: 'Seu valor para CPM é menor que o preço da rede',
                text: 'O&nbsp;valor inserido para CPM &eacute;&nbsp;menor que o&nbsp;custo que a&nbsp;rede de&nbsp;parceiros exige.Voc&ecirc; poder&aacute; salvar a&nbsp;campanha, mas n&atilde;o funcionar&aacute; at&eacute; que o&nbsp;valor seja corrigido.',
              },
            },
            streamers: {
              label: 'Selecção de Streamers',
              placeholder: 'Seleccionar serpentinas',
            },
            language: {
              label: 'Língua de tradução',
              placeholder: 'Seleccionar idioma',
            },
            countries: {
              label: 'Países',
              placeholder: 'Seleccione o país',
            },
            gender: {
              label: 'Género',
              placeholder: 'Seleccionar género',
            },
            age: {
              label: 'Idade dos Streamers',
              fromPlaceholder: 'a partir de',
              toPlaceholder: 'para',
            },
            ageRestrictions: {
              label: 'Streamer tem restrições de idade activadas',
            },
            tags: {
              label: 'Etiquetas',
              placeholder: 'Seleccionar etiqueta',
            },
          },
        },
        labels: {
          title: '<strong>Selo de patrocínio</strong><br/>(apenas mercado CIS)',
          fields: {
            chatbot: {
              label: 'Chatbot erid ID',
            },
            creative: {
              label: 'Creative erid ID',
            },
          },
        },
        chatBot: {
          title: 'Configurações do chatbot',
          form: {
            productUrl: {
              label: 'Link do produto',
              placeholder: 'Insira o link',
            },
            productUrlShort: {
              label: 'URL curto',
              placeholder: '',
            },
            chatBotText: {
              label: 'Texto para chatbot',
              placeholder: 'Digite o texto',
            },
          },
        },
        creative: {
          title: 'Dados criativos',
          form: {
            productUrl: {
              label: 'Link do produto',
              placeholder: 'Insira o link',
            },
            videoDescriptionText: {
              label: 'Descripción Texto',
              placeholder: 'Digite o texto',
            },
          },
        },
        preview: {
          title: 'Requisitos',
          requirements: 'Resolução: 1920 x 1080 pixels.<br/>Formato: MP4<br/>Tamanho: até {size} MB',
          errors: {
            unknown: 'Erro desconhecido',
          },
        },
        messages: {
          groupUpdatedSuccesfully: 'Grupo atualizado com sucesso',
        },
        analytics: {
          title: 'Configuração do Analytics',
          pixelClicks: {
            label: 'Pixel por clique',
            placeholder: 'Introduzir link',
            addLabel: 'Adicionar mais um rastreador',
          },
          pixelClicksScripts: {
            label: 'Pixel de código',
            placeholder: 'Insira o código',
          },
          erid: {
            title: 'ERID (apenas mercado CIS)',
            label: 'erid ID',
            placeholder: '',
          },
        },
      },
      creative: {
        defaultName: 'Nome Craetivo',
        defaultNameNew: 'Novo Criativo',
        loading: 'Creative loading ..',
        form: {
          status: {
            label: 'Status',
          },
          name: {
            title: 'Nome criativo',
            label: 'O nome é visível apenas para si',
            placeholder: 'Introduzir texto',
          },
          adtag: {
            title: 'Google Ad Tag',
            label: '',
            placeholder: '',
          },
          files: {
            title: 'Carregar ficheiros',
            preview: 'Carregar o arquivo de visualização',
            formats: {
              video: 'Vídeo',
              videoOrImage: 'Vídeo ou imagem',
              html5: 'HTML5',
              banner: 'Banner',
            },
            requirements: {
              title: 'Requisitos',
              fullscreen: 'Duração: 15s<br/>Resolução: 1920 x 1080<br/>Formato: H.264 (MP4, webm)<br/>Áudio: -9dB<br/>Taxa de bits: 2000 - 5500 kbps<br/>Taxa de quadros: 24 - 30<br/>Tamanho: até 10 MB',
              leaderboard: 'Duração: 15s<br/>Resolução: 1920 x 270<br/>Formato: H.264 (MP4, webm)<br/>Áudio: Desativado<br/>Taxa de bits: 2000 - 5500 kbps<br/>Taxa de quadros: 24 - 30<br/>Tamanho: até 5MB',
              pip_video: 'Duração: 15s<br/>Resolução: de 550 x 310<br/>até 1920 x 1080 pixels.<br/>Formato: H.264 (MP4, webm)<br/>Áudio: Desativado<br/>Taxa de bits: 2000 - 5500 kbps<br/>Taxa de quadros: 24 - 30<br/>Tamanho: até 5MB',
              custom: 'Duração: 15s<br/>Resolução: 1920 x 1080<br/>Formato: PNG, JPG ou GIF<br/>HTML de terceiros: arquivo zip HTML5<br/>Áudio: Desativado<br/>Taxa de quadros: 24 - 30<br/>Tamanho: até 10 MB',
            },
            errors: {
              general: 'Forneça um arquivo criativo válido',
            },
            instructions: {
              dragImage: 'Arraste a foto aqui',
              dragImageOrVideo: 'Arraste uma imagem ou vídeo aqui',
              dragVideo: 'Arraste o vídeo aqui',
              dragZip: 'Arraste o arquivo com arquivos aqui',
            },
          },
          creativeManager: {
            messages: {
              deleted: 'O anexo foi excluído',
            },
            errors: {
              unknown: 'Erro desconhecido',
            },
          },
          advice: {
            title: 'Creative preview',
            description: 'Estimate how your ad will look',
            button: 'Show preview',
          },
          data: {
            title: 'Dados criativos',
            fields: {
              erid: {
                title: 'ERID (apenas mercado CIS)',
                label: 'erid ID',
                placeholder: '',
              },
              productUrl: {
                label: 'Link para o seu produto',
                placeholder: 'Introduzir link',
              },
              chatbotText: {
                label: 'Texto do bot do Chat',
                placeholder: 'Introduzir texto',
              },
              companionHeading: {
                label: 'Companion banner title',
                placeholder: 'Enter title',
              },
              companionText: {
                label: 'Texto da faixa de acompanhamento',
                placeholder: 'Introduzir texto',
              },
              companionCta: {
                label: 'Chamada à acção',
                placeholder: 'Introduzir texto',
              },
              qrCode: {
                label: 'Gerar o código QR',
              },
              pixelClicks: {
                label: 'Pixel por clique',
                placeholder: 'Introduzir link',
              },
              pixelClicksScripts: {
                label: 'Pixel de código',
                placeholder: 'Insira o código',
              },
              pixelImpressions: {
                label: 'Pixel por vista',
                placeholder: 'Introduzir link',
                addLabel: 'Adicionar mais um rastreador',
              },
              pixelInspections: {
                label: 'Pixel para inspecção',
                placeholder: 'Introduzir link',
                addLabel: 'Adicionar mais um rastreador',
              },
            },
          },
          labels: {
            title: '<strong>Selo de patrocínio</strong><br/>(apenas mercado CIS)',
            fields: {
              chatbot: {
                label: 'Chatbot erid ID',
              },
              creative: {
                label: 'Creative erid ID',
              },
            },
          },
          formSendStatus: {
            updated: 'Сriativo foi atualizado',
          },
        },
      },
      profile: {
        header: {
          title: 'Perfil',
        },
        agency: {
          title: 'Agência',
        },
        settings: {
          title: 'Sobre você',
        },
        password: {
          title: 'Alterar a senha',
        },
      },
      analytics: {
        campaignOverview: 'Resumo da campanha',
        creators: 'Criadores',
        categories: 'Categorias',
        status: 'Status',
        active: 'Ativo',
        inactive: 'Inativo',
        updated: 'Atualizado',
        downloadCSV: 'Baixar CSV',
        downloadExcel: 'Baixar Excel',
        impressions: 'Vistas',
        CTR: 'CTR',
        spent: 'Gasto',
        fills: 'Preenchimentos',
        reach: 'Alcance',
        channels: 'Canais',
        avgCPM: 'CPM Médio',
        totalClicks: 'Total de cliques',
        clicks: 'Cliques',
        adFormat: 'Formato',
        outOf: 'De',
        creator: 'Criador',
        category: 'Categoria',
        runATestCampaignToday: 'Faça hoje uma campanha de teste!',
        viewability: 'Visibilidade',
        overviewTab: {
          title: 'Interactive da campanha',
          performanceTitle: 'Interactive por dia',
          creativeStats: 'Estatísticas dos criativos',
          allCategories: 'Todas as categorias',
          groupName: 'Nome do grupo',
          creativeName: 'Nome do criativo',
          top5Channels: 'Top 5 canais',
          top5Categories: 'Top 5 categorias',
          creatorsLinkCaption: 'Ir a todos os criadores',
          categoriesLinkCaption: 'Ir para todas as categorias',
        },
        creatorsTab: {
          title: 'Interactive dos criadores',
        },
        categoriesTab: {
          title: 'Interactive das categorias',
          otherCategories: 'Outras categorias',
        },
      },
      advertisers: {
        header: {
          title: 'Anunciantes',
        },
        tabs: {
          advertisers: 'Anunciantes',
          holdings: 'Participações',
        },
        advertisers: {
          table: {
            advertiser: 'Anunciante',
            currency: 'Moeda',
            balance: 'Equilíbrio',
            links: 'Links (Patrocínio)',
          },
          links: {
            campaigns: 'Campanhas',
            groups: 'Grupos',
            creatives: 'Criativos',
            holdings: 'Participações',
          },
        },
        holdings: {
          table: {
            holding: 'Participações',
            description: 'Descrição',
          },
        },
      },
    },
    widget: {
      adForSupport: 'A promoção por',
      willStartIn: 'começará em',
      poweredBy: 'Powered by Uplify',
      of: 'of',
      reloginMessage: {
        title: 'Atenção, {platform} atualizado!',
        description: 'Sua versão do widget está desatualizada. Por favor, saia e faça o login na conta.',
        streamer: {
          label: 'Atenção',
          title: '{platform} atualizado!',
          description: 'Sua versão do widget está desatualizada. Por favor, saia e faça o login na conta.',
          button: 'Entrar novamente',
        },
      },
      chatbotErrorMessage: {
        title: 'Chatbot não funciona',
        description: 'Por favor, reconecte o&nbsp;bot em&nbsp;sua conta pessoal. Instru&ccedil;&otilde;es passo a&nbsp;passo: fix.uplify.app/chatbot&rsquo;',
      },
      resolutionErrorMessage: {
        title: 'Widget configurado incorretamente',
        description: 'Configure o&nbsp;widget seguindo as&nbsp;instru&ccedil;&otilde;es passo a&nbsp;passo: <span>fix.uplify.app/widget</span>',
      },
      tooManySubscribers: {
        title: 'O widget foi bloqueado',
        description: 'Exclua todas as cópias do widget e atualize a camada.',
      },
      platformDisclaimer: 'não patrocina ou endossa esta promoção e não é responsável por ela.',
      platformDisclaimerYoutube: 'Esta promoção é feita pela streamer, independentemente do YouTube.',
      freemium: {
        timeLeft: '{time} minutos restantes',
        votesLeft: 'Votos - {num} restantes',
        pollWinner: 'Escolha',
        goalQrTitle: 'Apoie-me em',
        goalTopSuppoerters: {
          day: 'Top apoiadores: hoje',
          week: 'Top apoiadores: 7 dias',
          quarter: 'Top apoiadores: o tempo todo',
          alltime: 'Top apoiadores: o tempo todo',
        },
        supporterWatcherSponsorVideo: 'assisti vídeo de patrocínio',
        anonymous: 'Alguém',
        supportedYou: 'te apoiou',
        chatbotMsgs: [
          'Apoie-me assistindo aos anúncios {link}',
          'As doações sem dinheiro foram inventadas aqui {link}',
          'Agora é fácil suportar meu fluxo: Vá para o link e veja os anúncios {link}',
          'Doações, gorjetas e mamas são aceitas aqui. Não é necessário cartão {link}',
          'Apoie-me novamente, mesmo que você já tenha clicado no link {link}',
          'Você não precisa de um cartão bancário quando as doações são gratuitas {link}',
          'Cada clique neste link é seu apoio ao meu fluxo {link}',
          'Doações sem SMS e registro - {link}',
        ],
      },
    },
    panel: {
      tabs: {
        widget: 'Configurações',
        campaigns: 'Campanhas',
      },
      states: {
        title: 'Statures',
      },
      params: {
        title: 'Opções de exibição de anúncios',
        previewCaption: 'Visualização de patrocínio na transmissão',
        locationCaption: 'Localização na transmissão',
      },
      campaigns: {
        title: 'Campanhas disponíveis',
        none: 'Não há campanhas disponíveis',
        comeBack: 'Todas as campanhas terminaram hoje.<br>Venha de volta amanhã!',
      },
      error: {
        title: 'A ligação está desactualizada',
        text: 'Vá ao seu escritório pessoal para o novo link',
      },
      statusesMustBeOn: 'Para veicular anúncios, todos os estados devem estar em',
      logger: {
        copy: 'Diário de cópias',
        copied: 'O log é copiado!',
      },
    },
    page404: {
      pageNotFound: 'Página não encontrada',
      back: 'Voltar para a página principal',
      question: 'Tem uma pergunta?',
      chat: 'Fale connosco',
    },
  },
}

export default dictionary
