<template>
  <ElTableColumn
    :label="locale.label"
    width="120px"
  >
    <template slot-scope="{ row }">
      <div
        class="table-column-platform"
        :class="`table-column-platform_${row.platform || row.ad_set.platform}`"
      >
        <SvgIcon
          :name="`${row.platform || row.ad_set.platform}-icon`"
          width="16"
          height="16"
          :original="row?.platform === 'vkplay' || row?.ad_set?.platform === 'vkplay'"
        />
        {{ getPlatformName(row.platform || row.ad_set.platform) }}
      </div>
    </template>
  </ElTableColumn>
</template>

<script>
import ElTableColumn from 'element/ElTableColumn'

export default {
  components: {
    ElTableColumn,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.platform'),
    }),
  },

  methods: {
    getPlatformName (platform) {
      if (platform === 'twitch') {
        return 'Twitch'
      }
      if (platform === 'youtube') {
        return 'YouTube'
      }
      if (platform === 'trovo') {
        return 'Trovo'
      }
      if (platform === 'vkplay') {
        return 'VK Play'
      }
      else {
        return '—'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-column-platform {
  display: flex;
  align-items: center;

  ::v-deep {
    .svg-icon {
      margin-right: 8px;
    }
  }

  &_twitch {
    ::v-deep {
      .svg-icon {
        fill: $--color-twitch;
      }
    }
  }

  &_youtube {
    ::v-deep {
      .svg-icon {
        fill: $--color-youtube;
      }
    }
  }

  &_trovo {
    ::v-deep {
      .svg-icon {
        fill: $--color-trovo;
      }
    }
  }
}
</style>
