<template>
  <DragNDropTemplate
    :options="options"
    @change="$emit('change', $event)"
    @error="$emit('error', $event)"
  />
</template>

<script>
import DragNDropTemplate from './DragNDropTemplate'

export default {
  components: {
    DragNDropTemplate,
  },

  computed: {
    title: ({ $rootLocale }) => $rootLocale('views.partner.creative.form.files.instructions.dragVideo'),
    options: ({ title }) => ({
      title,
      icon: {
        name: 'creative-video',
        width: '44px',
        height: '36px',
      },
      maxSizeMb: 15,
      displayMaxSizeMb: 10,
      accept: 'video',
    }),
  },
}
</script>
