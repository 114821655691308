import { AdvertisingMode, AdvertisingPosition, IWidget, LeaderboardPosition } from 'lib/types/widget'

export const defaultWidget: IWidget = {
  url: '',
  obsDockSlug: '',
  slug: '',
  platform: 'twitch' as const,
  boxSize: 0,
  referralPromotion: false,
  stream: {
    delay: 0,
    enabled: false,
  },
  advertising: {
    mode: AdvertisingMode.AUTO,
    frequency: 0,
    position: AdvertisingPosition.LEFT_TOP_CORNER,
  },
  leaderboard: {
    position: LeaderboardPosition.TOP,
  },
  adManualEnabled: false,
  ignoreCategories: [],
  enabled: false,
  botEnabled: false,
  extensionEnabled: false,
  nightbot: {
    twitch: {
      connected: false,
      moderator: false,
    },
    youtube: {
      connected: false,
      moderator: false,
    },
    trovo: {
      connected: false,
      moderator: false,
    },
  },
  streamer: null,
  twitch: null,
  youtube: null,
  trovo: null,
  vkplay: null,
  relogin: false,
  bsRequired: false,
  allowAdultContent: false,
  sspTextFrequency: 2,
}
